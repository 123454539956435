.has-teasers {
  @extend .is-layout-element;

  margin-left: 0;
  margin-right: 0;

  a,
  span.is-a {
    $duration: 0.5s;

    position: relative;
    color: white;

    flex: none;
    width: 50%;
    display: flex;
    flex-direction: column;

    @include tablet {
      width: (100% / 3);
    }

    picture {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex-grow: 1;
    }

    img {
      display: block;
      width: 100%;
      flex-grow: 1;
      object-fit: cover;
      max-width: none;
      transition: all $duration ease-in-out;
    }

    div {

      @mixin padding($padding) {
        padding: $padding;
        span {
          bottom: $padding;
          left: $padding;
        }
      }

      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($palmer-blue, 0.8);
      transition: all $duration ease-in-out;
      opacity: 0;

      h2, span {
        font-family: $univers-condensed;
        font-size: (24px / 2);
        line-height: (34px / 2);
        text-transform: none;

        @include tablet {
          font-size: (36px / 2);
          line-height: (46px / 2);
        }

        @include desktop {
          font-size: 30px;
          line-height: 40px;
        }
      }

      span {
        position: absolute;
      }

      @include padding((36px / 2));
      @include tablet {
        @include padding((50px / 2));
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
      div {
        opacity: 1;
      }
    }

    &.is-link,
    &.is-article {
      div {
        background: $palmer-red;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.5rem;
      }
    }

    &.is-link {
      @include tablet {
        margin-top: ($spaceBetweenElements-tablet / 2);
        margin-left:  auto !important;
        margin-right:  auto !important;
        height: (80px / 2);

        div {
          span {
            font-size: (26px / 2);
            top: 3px;
          }

          &:after {
            height: (80px / 2);
          }
        }

      }

      div {
        &:after {
          content: '';
          height: 100%;
          background: url(../Images/arrow-right.svg) left center no-repeat;
          width: 10px;
          margin-left: 8px;
        }

        span {
          font-family: $univers-condensed-bold;
          text-transform: uppercase;
          top: 0;
          left: 0;
          position: relative;
          font-size: (34px / 2);
          line-height: (44px / 2);
        }
      }
    }


    &.is-article {
      div {
        padding: 14px;
      }

      h2 {
        font-family: $univers-condensed-bold;
        font-size: (34px / 2);
        line-height: (44px / 2);
        text-align: center;
      }

      span {
        opacity: 0;
        position: absolute;
        bottom: (30px / 2);
        left: (30px / 2);
        top: auto !important;

        @include tablet {
          font-size: (34px / 2) !important;
          bottom: (50px / 2);
          left: (50px / 2);
        }

        @include desktop {
          font-size: 30px !important;
        }
      }

      h2, span {
        transition: all $duration ease-in-out;
      }

      &.is-half-mobile {
        height: 50vw;
      }

      &:hover {
        h2 {
          opacity: 0.3;
        }
        span {
          opacity: 1;
        }
      }

      @include tablet {
        h2 {
          font-size: (58px / 2);
          line-height: (68px / 2);
        }
        &.is-one-third-tablet {
          height: (100vw / 3);
        }
      }
    }

    &.is-article {
      border-color: white;
      border-style: solid;
      border-width: 0;

      &:nth-child(1) {
        border-width: 0 1px 1px 0;
      }

      &:nth-child(2) {
        border-width: 0 0 1px 0;
      }

      &:nth-child(3) {
        border-width: 0 1px 0 0;
      }

      @include tablet {
        border-width: 0 !important;

        &:nth-child(2) {
          border-width: 0 1px !important;
        }
      }
    }
  }

  &.projects {
    a,
    span.is-a {
      &.is-link {
        @include tablet {
          margin-bottom: ($spaceBetweenElements-tablet / 2);
        }
      }
    }
  }


}

.has-teasers:last-child {
    @include tablet {
      @include layout-element(1.5);
    }
}

.section-header {
  @extend .is-gapless-layout-element;
  font-size: (34px / 2);
  font-family: $univers-condensed-bold;
  line-height: (92px / 2);
  color: $palmer-red;
  text-align: center;
  margin-bottom: 0;

  @include tablet {
    font-size: (58px / 2);
    line-height: (172px / 2);
  }

  & + hr {
    margin: 0 0 (68px / 2);
    border-bottom: 1px solid $palmer-red;

    @include tablet {
      margin-bottom: (130px / 2);
    }

    &:after {
      $size: 22px;
      content: '';
      display: block;
      width: $size;
      height: $size;
      border-style: solid;
      border-color: $palmer-red;
      border-width: 0 0 1px 1px;
      background: white;
      position: absolute;
      left: 50%;
      margin-left: -($size / 2);
      transform: translateY(-(($size - 1)/ 2)) rotate(-45deg);

      @include tablet {
        $size: 40px;
        width: $size;
        height: $size;
        margin-left: -($size / 2);
        transform: translateY(-(($size - 1)/ 2)) rotate(-45deg);
      }
    }
  }
}
