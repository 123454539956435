.elancer-palmer-backgroundcontainer {

    lottie-player {
        width: 200px;
        height: 200px;
    }

    &.services-block {
        lottie-player {
            width: 237px;
            height: 237px;
        }
    }

    &.automotive-h2-style {
        h2 {
            font-family: $univers-condensed-bold;
        }
    }

    &.h2-h3-automotive {
        h2 {
            font-family: $univers-condensed-bold;
            margin-bottom: 0;
        }

        h3 {
            font-family: $univers-condensed;
            text-transform: uppercase;
            color: $palmer-grey;
            margin-bottom: 10px;
        }
    }

    &.video-fullscreen {
        video {
            width: 100%;
        }
    }

    &.max-precision-block {
        .is-full-image-container {
            margin-top: -69%;
            margin-bottom: 0 !important;
        }
    }

    &.specialist-block {
        display: flex;
        align-items: center;
        align-content: center;
        padding: 70px 0 60px;

        > .neos-contentcollection {
            width: 100%;
        }

        .text-container {
            padding: 0;
            max-width: 925px;

            h2 {
                color: white;
                text-align: center;
                text-transform: uppercase;
                font-family: $univers-condensed-bold;
            }
        }
    }

    &.services-block {
        .elancer-palmer-textgridtwocolumns {
            .column:first-child {
                margin-left: -16.5px;
            }

            .neos-contentcollection {
                .typo3-neos-nodetypes-text {
                    position: relative;

                    &:nth-child(2) {
                        padding-left: 40px;

                        &::before {
                            content: " ";
                            position: absolute;
                            height: 100%;
                            width: 38px;
                            background: url('../Images/mass.svg') no-repeat;
                            left: -10px;
                            background-size: cover;
                            top: -3px;
                        }
                    }
                }
            }

            .column:first-child {
                .neos-contentcollection {
                    height: 100%;

                    .typo3-neos-nodetypes-html {
                        height: 100%;
                        display: flex;

                        lottie-player {
                            margin: auto;
                        }
                    }
                }
            }
        }
    }

    &.topic-block {
        .elancer-palmer-textgridtwocolumns {
            .typo3-neos-nodetypes-text {
                position: relative;

                &::before {
                    content: " ";
                    position: absolute;
                    height: 100%;
                    width: 38px;
                    background: url('../Images/mass.svg') no-repeat;
                    left: -10px;
                    background-size: cover;
                    top: -3px;
                }

                p {
                    padding-left: 40px;
                }
            }
        }

        .column:nth-child(2) {
            margin-left: -16.5px;

            .neos-contentcollection {
                height: 100%;

                .typo3-neos-nodetypes-html {
                    height: 100%;
                    display: flex;
                    -ms-flex-pack: end;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
        }
    }

    &.integrated-customer-block {
        padding: 60px 0 10px;

        h2 {
            font-family: $univers-condensed-bold;
        }

        h2, p {
            color: white;
        }
    }

    &.references-block {
        .neos-contentcollection {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            padding: 10px 0 50px;

            lottie-player {
                margin: auto;
            }
        }

        h2 {
            font-family: $univers-condensed-bold;
        }
    }

    &.interest-block {
        .is-full-image-container {
            margin-bottom: -30% !important;
        }

        h2 {
            font-family: $univers-condensed-bold;
        }
    }
}


@media screen and (min-width: 768px) {
    .elancer-palmer-backgroundcontainer {
        &.center-text {
            .text-container {
                padding: 0;
                max-width: 888px;
            }
        }

        &.specialist-block {
            height: 100vh;
            padding: 0;
        }

        &.services-block {
            .elancer-palmer-textgridtwocolumns {
                .column:first-child {
                    margin-left: 0;
                }

                .neos-contentcollection {
                    .typo3-neos-nodetypes-text {
                        &:nth-child(2) {
                            padding-left: 0;

                            &::before {
                                width: 56px;
                                left: -80px;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        &.topic-block {
            .elancer-palmer-textgridtwocolumns {
                .column:nth-child(2) {
                    margin-left: 0;
                }

                .typo3-neos-nodetypes-text {
                    &::before {
                        width: 56px;
                        left: -80px;
                        top: 0;
                    }

                    p {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 769px) {
    .elancer-palmer-backgroundcontainer {
        &.topic-block,
        &.services-block {
            p {
                margin-bottom: 0 !important;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .elancer-palmer-backgroundcontainer {
        &.automotive-h2-style {
            h2 {
                font-size: 45px;
            }
        }

        &.h2-h3-automotive {
            h2 {
                font-size: 50px;
                margin-bottom: 5px;
            }

            h3 {
                font-size: 50px;
                margin-bottom: 45px;
            }
        }

        &.specialist-block {
            &.center-text .text-container {
                max-width: 1000px;
            }

            .text-container {
                h2 {
                    font-size: 100px;
                    line-height: 117px;
                }
            }
        }

        &.services-block {
            .elancer-palmer-textgridtwocolumns {
                max-width: 1440px;
                padding: 170px 0;
            }
        }

        &.topic-block {
            padding: 150px 0;
        }

        &.references-block {
            .neos-contentcollection {
                padding: 200px 0 250px;
            }
        }

        &.integrated-customer-block {
            padding-top: 100px;
            padding-bottom: 10px;
        }

        &.interest-block {
            padding-top: 200px;
        }
    }
}

@media screen and (max-width: 767px) {
    .elancer-palmer-backgroundcontainer {
        &.max-precision-block {
            .text-container {
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }

        &.services-block {
            lottie-player {
                width: 130px !important;
                height: 130px !important;
                margin: 30px auto !important;
            }
        }

        &.topic-block {
            lottie-player {
                width: 130px !important;
                height: 130px !important;
                margin-bottom: 30px !important;
            }

            .column:nth-child(2) .neos-contentcollection .typo3-neos-nodetypes-html {
                justify-content: center;
            }
        }

        &.references-block {
            .neos-contentcollection {
                lottie-player {
                    width: 130px !important;
                    height: 130px !important;
                }
            }
        }
    }
}


.link-blue-button a {
    color: white;
    background: $onemagnify-blue;
    text-decoration: none !important;
    &:hover {
        color: $onemagnify-blue;
        background: white;
    }
}

.link-yellow-button a {
    color: $onemagnify-blue !important;
    background: $onemagnify-yellow !important;
    text-decoration: none !important;

    &:hover {
        color: $onemagnify-yellow !important;
        background: white !important;
    }
}

.link-yellow-button,
.link-blue-button {
    a {
        padding: 6px 15px;
        font-size: 15px;
    }
}

@media screen and (min-width: 768px) {
    .link-yellow-button,
    .link-blue-button {
        a {
            padding: 10px 30px;
            font-size: 17px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .link-yellow-button,
    .link-blue-button {
        a {
            font-size: 24px;
        }
    }
}

.max-width-img-1200 .is-full-image-container{
    max-width: 1200px;
    margin: auto;
}

@media screen and (max-width: 1023px) {
    .mobile-padding-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.h2-blue {
    h2 {
        color: $onemagnify-blue !important;
    }
}

.h2-yellow {
    h2 {
        color: $onemagnify-yellow !important;
    }
}

.no-max-width {
    .text-container {
        max-width: none !important;
    }
}

.big-yellow-h3 {
    h3 {
        color: $onemagnify-yellow;
        font-family: $univers-light;
        font-weight: bold;
        font-size: 80px;
        line-height: 80px;
    }
}

.facts-bottom-left {
    position: relative;

    &::after {
        content: 'facts';
        position: absolute;
        color: $onemagnify-yellow;
        font-family: $univers-condensed-bold;
        font-size: 95px;
        text-transform: uppercase;
        line-height: 70px;
        opacity: .3;
        top: 0;
        left: -8px;

        @include widescreen {
            font-size: 180px;
            line-height: 133px;
            top: unset;
            bottom: 160px;
            left: -140px;
            transform: rotate(-90deg);
        }
    }

    @include desktop {
        .elancer-palmer-textgridtwocolumns {
            .column {
                padding-left: 25px;

                &:first-child {
                    padding-left: 0px;
                    padding-right: 25px;
                }
            }
        }
    }
}

.facts-bottom-left-cn {
    &::after {
        content: '实际详情';

        @include widescreen {
            bottom: 288px;
            left: -260px;
        }
    }
}

.services-top-left {
    position: relative;

    &::after {
        content: 'services';
        position: absolute;
        color: $onemagnify-blue;
        font-family: $univers-condensed-bold;
        font-size: 95px;
        text-transform: uppercase;
        line-height: 70px;
        top: 2px;
        left: -8px;

        @include widescreen {
            font-size: 180px;
            line-height: 133px;
            top: 310px;
            left: -270px;
            transform: rotate(-90deg);
        }
    }

    .is-full-image-container {
        margin-bottom: 25px !important;

        img {
            width: auto;
        }
    }

    .elancer-palmer-textgridtwocolumns .is-full-image-container {
        margin-left: 0 !important;
    }
}

.services-top-left-cn {
    &::after {
        content: '服务';

        @include widescreen {
            top: 120px;
            left: -72px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .services-top-left-cn,
    .facts-bottom-left-cn {
        &::after {
            font-size: 66px !important;
            top: -6px !important;
            left: 0 !important;
        }
    }
}


.gradient-bg {
    background: radial-gradient(71.37% 71.37% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%), #003C4B;

    h1, h2, h3, p, ul li {
        color: white;
    }
}
