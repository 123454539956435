$body-background: white;
$family-sans-serif: "Univers LT 45 Light Regular", "Arial", sans-serif;
$size-6: $base-font-size;

$text: $palmer-blue;

@import "../../Scripts/node_modules/bulma/sass/utilities/_all.sass";
@import "../../Scripts/node_modules/bulma/sass/base/_all.sass";
//@import "bulma/sass/elements/elements.sass";
@import "../../Scripts/node_modules/bulma/sass/components/_all.sass";
@import "../../Scripts/node_modules/bulma/sass/grid/_all.sass";
@import "../../Scripts/node_modules/bulma/sass/layout/_all.sass";


.is-end-aligned {
  align-items: flex-end;
}

.is-start-aligned {
  align-items: flex-start;
}

.container {
  @include desktop {
    max-width: $widescreen;
  }
}

a:active,
a:focus {
  background-color: transparent;
}