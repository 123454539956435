.neos-contentelement.neos-contentelement-removed {
  display: none !important;
}

@include neos-controls(true) {

  margin-top: 0 !important;

  > main {
    padding: 50px 10px 0;
    margin-top: $neos-control-height;

    > .neos-contentcollection {
      @include layout-element;
    }
  }

  .blog-article > .neos-contentcollection {
    @include layout-element;
  }
}

body.neos-full-screen .neos-full-screen-close {
  z-index: 10001 !important;
}

@include neos-controls(true) {
  .hero:not(.is-parallax) {
    &.is-fullheight-tablet,
    &.is-fullheight {
      min-height: 50vh !important;
    }

    picture img {
      height: 50vh;
    }
  }
}

@include neos-backend(true) {

  .has-no-extra-padding {
    padding: 0 !important;
  }

  .press-page {
    background: rgba($palmer-red, 0.9);
    flex-grow: 1;
    padding: 60px 20px 20px;
    margin-top: $neos-control-height;
    color: white;
  }

  .elancer-palmer-teamvideo {
    overflow: hidden;
  }

  .neos-empty-contentcollection-overlay {
    flex-grow: 1;
  }

  .is-centered {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .is-closeup {
    background: white;
    .closeup-content {
      flex-grow: 1 !important;
      width: auto !important;
    }

    .closeup-body {
      min-height: 0 !important;
    }

    .closeup-bubble {
      left: 10px !important;
      margin-left: 0 !important;
    }

  }

  .is-member {
    .is-image {
      .neos-empty-contentcollection-overlay {
        width: 555px;
        height: (555px / (16 / 9));
        outline-offset: -2px;
      }
    }

    .social {
      min-width: 50px;
    }
  }
  .is-author {
    background: white;
    padding: 15px;
    width: 100%;
    color: $palmer-blue;

    .neos-contentcollection {
      margin-bottom: 0 !important;
    }

    .grid {
      display: flex !important;

      .is-title {
        padding-left: 0 !important;
      }
      .is-image {
        float: none !important;
        min-height: 100%;
        overflow: visible !important;
      }

      .is-text {
        .social {
          min-width: 100px;
          display: flex;
          justify-content: flex-end;

          > div {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .is-author {
    width: auto;
    margin: 0 20px;
    .image-column {
      width: 200px;
      flex: none;
      img {
        width: 100%;
      }
    }

    .social {
      display: flex;
      justify-content: flex-end;

      > * {
        margin-left: 5px;
      }
    }

    h1 {
      font-size: 20px;
      color: $palmer-red;
      text-transform: none;
    }

    h2 {
      font-size: 18px;
      text-transform: none;
    }


    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
}