.elancer-palmer-herophonemagnify {
    h1 {
        color: #fff;
        position: absolute;
        bottom: 0;

        padding: 0 33px;

        font-size: 43px;
        line-height: 43px;
        margin-bottom: -8px;

        @include tablet {
            font-size: 93px;
            line-height: 93px;
            margin-bottom: -14px;
        }

        @include desktop {
            font-size: 120px;
            line-height: 120px;
            margin-bottom: -20px;
        }

        @include widescreen {
            font-size: 170px;
            line-height: 199px;
            margin-bottom: -40px;
        }
    }
}

@media screen and (min-width: 760px) and (max-width: 768px) {
    .elancer-palmer-herophonemagnify {
        h1 {
            font-size: 93px;
            line-height: 93px;
            margin-bottom: -14px;
        }
    }
}

/* show correct in NEOS backend */

.neos-controls {
    .elancer-palmer-herophonemagnify {
        picture img {
            height: 100vh !important;
        }
    }
}
