.article-list {
  @extend .is-layout-element;

  margin: 0;
  padding: 0;
  list-style: none;
  color: white;

  $articleDuration: 0.5s;

  border-bottom: 1px solid white;
  text-transform: none;

  .jobs.yellow & {
    color: $palmer-blue;
    border-bottom: 1px solid $palmer-blue;
  }

  article {

    > a {
      color: white;
      display: block;
      position: relative;

      .jobs.yellow & {
        color: $palmer-blue;
      }

      &:after {
        content: '';
        background: url('../Images/arrow-right.svg') top left no-repeat;
        background-size: 100% 100%;
        display: block;
        position: absolute;
        right: 3px;
        top: 50%;
        margin-top: -10px;

        transform: rotate(90deg);
        transition: all $articleDuration ease-in-out;

        width: 20px;
        height: 20px;

        @include tablet {
          width: 25px;
          height: 25px;
        }

        .jobs.yellow & {
          background: url('../Images/arrow-right-blue.svg') top left no-repeat;
        }
      }

      @include tablet {
        display: flex;
        align-items: center;

        position: relative;
        top: 4px;

        time {
          width: 150px;
          flex: none;
        }

        &:after {
          margin-top: -14px;
        }
      }
    }

    > div {
      margin-top: 0;
      overflow: hidden;
      height: 0;
      max-width: 817px;

      .jobs & {
        max-width: none;
      }

      p, ul, ol {
        margin-bottom: 1em !important;
      }

      img {
        width: 100%;
      }

      transition:
        height $articleDuration ease-in-out ,
        margin $articleDuration ease-in-out;

      > p:last-child {
        margin-bottom: 0 !important;
      }
    }

    &.will-open {
      > div {
        transition: none;
        height: auto;
      }
    }

    &.is-open {

      a:after {
        transform: rotate(270deg);
      }

      > div {
        margin-top: 10px;
        transition:
          height $articleDuration ease-in-out,
          margin $articleDuration ease-in-out;

        @include tablet {
          margin-top: 20px;
        }
      }
    }

    &.force-open {
      > div {
        margin-top: 10px;
        overflow: visible;
        height: auto !important;
      }
    }
  }

  time {
    display: block;
    font-family: $univers-condensed-light;
    font-size: (22px / 2);
    line-height: (32px / 2);

    @include tablet {
      font-size: 26px;
      line-height: 28px;
    }
  }

  h2 {
    font-family: $univers-condensed-light;
    text-transform: uppercase;
    font-size: (34px / 2);
    line-height: (40px / 2);
    padding-right: 44px;
    margin: 0;

    @include tablet {
      font-size: (58px / 2);
      line-height: (72px / 2);
    }

    @include desktop {
      font-size: 50px;
      line-height: 50px;
    }
  }

  h3 {
    font-family: $univers-condensed-bold;
    font-size: (26px / 2);

    @include tablet {
      font-size: 26px;
      line-height: 28px;
    }
  }

  > li {
    border-top: 1px solid white;
    padding: (22px / 2) 0;

    .jobs.yellow & {
      border-top: 1px solid $palmer-blue;
    }
  }


  div.is-content {
    font-size: (30px / 2);
    line-height: (40px / 2);

    @include tablet {
      font-size: (34px / 2);
      line-height: (44px / 2);
      padding-right: 20vw;
    }
    p, ul {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &.with-time {
    h2 {
      font-size: (28px / 2);
      line-height: (28px / 2);

      @include tablet {
        font-size: 26px;
        line-height: 28px;
      }
    }
  }
}

.elancer-palmer-personiolist .article-list {

    article>a {
        @include tablet {
            padding-top: 5px;
            padding-bottom: 15px;
        }
    }

    h2 {
        text-transform: none;

        @include desktop {
            font-size: 32px;
        }
    }
}
