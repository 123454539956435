
nav.sub-nav.project-filter {
  @include neos-backend {
    margin-top: -65px !important;
  }
}

.projects {
  @extend .is-layout-element;

  a {
    flex: none;
    width: 50%;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    img {
      width: 100%;
      height: 50vw;
      object-fit: cover;
    }

    display: flex;

    @include tablet {
      width: (100% / 3);
      img {
        height: (100vw / 3);
      }
    }

    picture {
      flex-grow: 1;
    }

    &.is-filtered-out {
      opacity: 0;
      width: 0;

      img {
        width: 0;
      }
    }
  }

  &:last-child {
    @include layout-element(1);
  }

  .button-container {
    display: flex;
    width: 50%;
    flex: 1 1 auto;

    @include tablet {
      width: 100%;
    }

    > .is-link {
      width: 100%;

      @include tablet {
        width: 33.33333%;
      }
    }
  }
}

.text-with-logo {
  @include tablet {
    display: flex;
    padding-right: retina(114px);
    justify-content: space-between;

    .is-text {
      flex: none;
      width: retina(992px);
    }

    .is-image {
      margin-left: retina(173px);
      width: retina(344px);
    }
  }

  @include desktop {
    padding-right: 0;

    .is-text {
      width: 898px;
    }

    .is-image {
      margin-left: 71px;
      margin-right: -71px;
      width: 243px;
    }
  }
}
