.is-onemagnify-page {
    h2 {
        color: $ph-one-magnify-blue-gray;

        &.section-header {
            color: $palmer-red;
            border-top: 1px solid $palmer-red;
        }
    }

    .elancer-palmer-projectteaser {
        .button-container {
            a.is-link {
                & > div {
                    background: $onemagnify-blue;

                    span {
                        text-transform: none;
                        font-family: $univers;
                    }

                    &::after {
                        display: none !important;
                    }
                }
            }
        }
    }

    form.palmer-form {
        .is-element,
        input,
        textarea {
            color: $onemagnify-blue;
            border-bottom-color: $onemagnify-blue;

            &::placeholder,
            &:-ms-input-placeholder,
            &::-ms-input-placeholder {
                color: $onemagnify-blue;
            }
        }

        textarea {
            border-color: $onemagnify-blue;
        }

        .is-submit {
            color: $onemagnify-blue;
            background: $onemagnify-yellow;
            border: 1px solid $onemagnify-yellow;
            text-transform: none;
            font-family: $univers;

            &::after {
                display: none;
                background: transparent;
            }
        }
    }

    .elancer-palmer-pitchtwocolumns {
        .column {
            padding: 0 50px;
        }
    }

    .elancer-palmer-projects {
        .project-filter {
            display: none;
        }
    }
}

.onemagnify-header-hero {
    h1 {
        text-transform: none;
        color: white;
    }
}

.ph-onemagnify-gray {
    background: $ph-one-magnify-gray;
}

.white-ph-onemagnify-button a {
    background: white;
    color: $ph-one-magnify-black;
    text-decoration: none;
    padding: 8px 57px;
}

.white-ph-onemagnify-button a:hover {
    background: $ph-one-magnify-black;
    color: white;
}

.white-ph-onemagnify-button p {
    padding-bottom: 0;
}

.ph-onemagnify-p-tb {
    padding-top: 85px;
    padding-bottom: 85px;
}

.ph-onemagnify-banner {
    .text-container {
        padding: 0 33px !important;
    }
}

.p-b-80 {
    padding-bottom: 80px;
}

.m-b-80 {
    margin-bottom: 80px;
}

.p-t-100 {
    padding-top: 100px;
}

.ph-onemagnify-big-cite {
    height: 40vh;

    @include tablet {
        height: 100vh;

        .text-container {
            max-width: unset !important;
        }
    }

    > .neos-contentcollection {
        height: 100%;
        display: flex;
        align-items: center;

        h2 {
            font-family: $univers-condensed-bold;

            line-height: 33px;
            font-size: 26px;

            @include tablet {
                font-size: 66px;
                line-height: 76px;
            }

            @include desktop {
                font-size: 80px;
                line-height: 108%;
            }

            @include widescreen {
                font-size: 80px;
                line-height: 108%;
            }
        }
    }

    .text-container {
        padding: 0 33px !important;
    }
}

@media screen and (min-width: 700px) and (max-width: 768px) {
    .ph-onemagnify-big-cite > .neos-contentcollection h2 {
        line-height: 53px;
        font-size: 46px;
    }
}

// New elements

.phonemagnify-banner {
    &.center-text {
        h2, p {
            text-align: center;
        }
    }

    h2 {
        font-size: 30px;
        line-height: 42px;
        font-family: "Univers LT 67";
        padding-bottom: 10px;

        @include tablet {
            font-size: 38px;
            line-height: 38px;
            padding-bottom: 20px;
        }

        @include desktop {
            font-size: 50px;
            line-height: 65px;
            padding-bottom: 30px;
        }

        @include widescreen {
            font-size: 96px;
            line-height: 130px;
            padding-bottom: 40px;
        }
    }
}

