@import "../../Scripts/node_modules/select2/src/scss/core";
$light-blue: #91a4b1;

form.palmer-form {
  padding: 0 30px;

  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }

  @include layout-element(1.5);

  h2 {
    margin-bottom: 32px;
  }

  .help-block {
    margin: -20px 0 20px;
    color: $palmer-red;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    white-space: nowrap;
    margin-bottom: 20px;

    span.info {
      background: $palmer-blue;
      border-radius: 100px;
      display: inline-block;
      cursor: pointer;
      position: relative;
      top: -2px;

      &:before {
        $size: 22px;
        display: block;
        font-family: $univers-condensed-bold;
        font-size: 14px;
        height: $size;
        line-height: $size;
        width: $size;
        text-align: center;
        color: white;
        content: 'i';
        text-transform: none;
      }

      span {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        font-size: 15px;
        line-height: 1.2em;
        background-color: $palmer-blue;
        padding: 10px 20px;
        color: white;
        width: 362px;
        white-space: normal;
        bottom: 30px;
        left: 50%;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover span {
        opacity: 1;
      }
    }
  }

  .is-gapless {
    h3 {
      margin-bottom: 0;
    }
  }

  input[type="file"] {
    display: none;
  }

  .is-file {
    font-family: $univers-condensed-bold;
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;

    &:after {
      content: '';
      display: inline-block;
      background: url(../Images/arrow-down.svg) no-repeat;
      width: 20px;
      height: 20px;
      margin-left: 5px;
      font-size: 18px;
      transform:  translateY(3px) rotate(-90deg) scale(0.8);
    }
  }

  .files {
    border: 1px solid $light-blue;
    height: 100px;
    padding: 10px;
    overflow: auto;
    margin-bottom: 25px;

    div {
      display: flex;
      span {
        flex-grow: 1;
      }

      button {
        border: none;
        background: transparent;
        cursor: pointer;
        margin: 0;
        padding: 0;
        font-size: 17px;
      }
    }
  }

  .g-recaptcha {
    margin-bottom: 25px;
  }

  .is-element {
    display: block;
    border-bottom: 1px solid $light-blue;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 25px;
    font-family: $univers-light;
    color: $palmer-blue;
    font-size: 18px;
    padding: 10px 0;
    width: 100%;

    @include placeholder {
      color: $light-blue;
    }
  }

  .has-error {
    .is-element {
      color: $palmer-red;

      @include placeholder {
        color: $palmer-red;
      }
    }
  }

  .is-form {
    margin-top: 0;
    margin-bottom: 0 !important;
    .column {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .is-element + h2 {
    margin-top: 32px;
  }


  select {
    width: 100%;
  }

  input, textarea {
    @extend .is-element;
  }

  textarea {
    border: 1px solid $light-blue;
    resize: none;
    height: 150px;
    padding: 20px;
  }

  label.checkbox {
    margin-bottom: 25px;
    display: flex;
    cursor: pointer;

    input {
      display: none;
    }

    a {
      text-decoration: underline;
    }

    input + span {
      display: block;
      width: 24px;
      height: 24px;
      border: 1px solid $palmer-blue;
      flex: none;
      margin-right: 15px;
    }

    input:checked + span {
      background: url('../Images/check.svg') center center no-repeat;
    }
  }

  .has-error label.checkbox {
    color: $palmer-red;
  }

  .is-submit {
    border: none;
    font-size: 18px;
    line-height: 41px;
    text-transform: uppercase;
    font-family: $univers-condensed-bold;
    color: white;
    background: #8a8b8c;
    padding: 0 16px;
    cursor: pointer;

    &:after {
      content: '';
      display: inline-block;
      background: url(../Images/arrow-right.svg) no-repeat;
      width: 15px;
      height: 20px;
      margin-left: 10px;
      position: relative;
      top: 3px;
    }
  }
}

.select2-container {
  margin-bottom: 25px;
  max-width: 100%;

  @include neos-backend {
    width: 100% !important;
  }

  .select2-selection {
    border: none;
    border-bottom: 1px solid $light-blue !important;
    border-width: 0 0 1px 0 !important;
    border-radius: 0;
    line-height: 0;
    height: auto;
    background-color: transparent;

    .select2-selection__rendered {
      font-family: $univers-light;
      color: $palmer-blue;
      font-size: 18px;
      padding: 10px 0;

      .select2-seleection__placeholder {
        color: $light-blue;
      }
    }

    .select2-selection__arrow {
      background: url(../Images/arrow-down.svg) no-repeat;
      width: 18px;
      height: 11px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -5px;
      pointer-events: none;
      transition: all 0.5s;

      b {
        display: none;
      }
    }
  }

  &.select2-container--open .select2-selection__arrow {
    transform: rotate(180deg);
  }

  .select2-dropdown {
    border-radius: 0;
    border: none;

    .select2-results__option {
      padding: 0;
      line-height: 37px;
      border-bottom: 1px solid #32373c;

      &[aria-selected=true],
      &.select2-results__option--highlighted {
        color: $palmer-red;
        background-color: transparent;
      }
    }
  }
}

.select2-container.select2-container--multiselect {
  .select2-selection.select2-selection--multiple {
    .select2-selection__rendered {
      padding: 0;
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      width: 100%;

      &:after {
        content: '';
        display: block;
        background: url(../Images/arrow-down.svg) no-repeat;
        width: 18px;
        height: 11px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -5px;
        pointer-events: none;
        transition: all 0.5s;
      }
    }

    .select2-selection__choice {
      display: none;
    }

    .select2-search--inline {
      float: none !important;
      input {
        width: 100% !important;
      }
    }

    input[type="search"] {
      margin: 0;
      padding: 10px 0;
      pointer-events: none;
    }
  }

  &.select2-container--open .select2-selection__rendered:after {
    transform: rotate(180deg);
  }

  .select2-dropdown {
    .select2-results__options {
      max-height: 266px;
      overflow: auto;
    }
    .select2-results__option {
      &:before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 1px solid $palmer-blue;
        flex: none;
        margin-right: 15px;
        position: relative;
        left: 5px;
        top: 6px;
      }

      &[aria-selected=true]:before {
        background: url('../Images/check.svg') center center no-repeat;
      }
    }
  }
}

.has-error {
  .select2-selection__placeholder {
    color: $palmer-red !important;
  }
}

#aufmerksam-geworden h3 {
  white-space: normal;
}

@media (max-width: 768px) {
  form {
    .is-file {
      margin-top: 15px !important;
    }
  }
}

@media (max-width: 1024px) {
  form.palmer-form h3 span.info span {
    width: 270px;
    left: -200px;
  }
}
