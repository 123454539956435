@import "../../Scripts/node_modules/cookieconsent/build/cookieconsent.min.css";

.cc-window {
  padding: 10px 32px !important;

  .cc-message {
    font-family: "Univers LT 45 Light Regular", sans-serif !important;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 1em;
    text-align: center;

    @include tablet {
      text-align: left;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .cc-link {
    padding: 0;
    opacity: 1;
  }

  a:not(.confirm) {
    text-decoration: underline;
  }

  .cc-btn {
    @extend %icon-button;

    @include tablet {
      line-height: (82px / 2);
    }

    &:active, &:focus, &:hover {
      background: white !important;
      color: $palmer-red !important;
    }
  }

  &:not(.cc-invisible) {
    ~ footer {
      padding-bottom: 125px;
    }
  }
}

// cookiebot
.ph-cookieconsent-optout {
    background: $palmer-red;
    text-align: center;
    padding: 15px;
    color: white;
    font-weight: bold;

    a {
        text-decoration: underline;
    }
}
