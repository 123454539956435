
@keyframes hide {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

@keyframes show {
  to {
    visibility: visible;
  }
}

@mixin hide($delay) {
  animation: hide 0s ease-in $delay forwards;
}

@mixin show($delay) {
  animation: show 0s ease-in $delay forwards;
}

@function retina($value) {
  @return ceil($value / 2);
}

