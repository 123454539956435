body {
  line-height: 22px;
}

h1, h2 {
  text-transform: uppercase;
  font-family: $univers-condensed-bold;
  line-height: (54px / 2);
  font-size: (44px / 2);

  margin-bottom: retina(25px);

  @include tablet {
    font-size: (72px / 2);
    line-height: (72px / 2);
  }

  @include desktop {
    font-size: 50px;
    line-height: 65px;
  }
}

h1 + h2 {
  margin-top: retina(-25px);
}

a, a:hover, a:active, a:focus, strong {
  color: inherit;
}

h2 {
  font-family: $univers-condensed-light;
}

p, .is-content ul, .typo3-neos-nodetypes-text ul {
  font-family: $univers-light;
  font-size: (34px / 2);
  line-height: (44px / 2);
  color: inherit;

  @extend .is-layout-element;

  @include desktop {
    font-size: 24px;
    line-height: 32px;
  }
}

.is-content ul,
.typo3-neos-nodetypes-text ul {
  position: relative;
  $offset: 20px;
  margin-left: $offset;
  li {

    &:before {
      content: '●';
      display: block;
      position: absolute;
      left: -$offset;
    }
  }

  .jobs &,
  &.neos-list-square {
    list-style: square;
    $offset: 27px;
    margin-left: $offset;

    li {
      &:before {
        content: none;
      }
    }
  }
}

.text-container,
.full-text-container {
  p {
    a {
      text-decoration: underline;
      word-break: break-all;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
