#ceo-linkedindex-button {
    position: fixed;
    right: 42px;
    bottom: 37px;
    z-index: 110;
    height: 100px;
    width: 100px;
    transform-origin: center;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.ceo-circle {
            z-index: 100;
        }

        &.ceo-link {
            width: 60%;
            opacity: 0;
            z-index: 102;
        }

        &.ceo-text {
            opacity: 1;
            z-index: 101;
            width: 50%;
        }
    }
}

@media screen and (min-width: 1024px) {
    #ceo-linkedindex-button {
        height: 166px;
        width: 166px;

        img {
            &.ceo-link {
                width: unset;
            }
        }
    }
}
