.closeup {
  $duration: 0.5s;
  $text-duration: ($duration / 2);
  $track-height: 137px;

  position: relative;
  overflow: hidden;

  .lSSlideOuter {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .lSSlideWrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .closeup-body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .closeup-slider {
      flex-grow: 1;
      height: auto !important;

      .closeup-content {
        flex: none;
        display: none;

        &:first-child {
          display: block;
        }
      }

      &.lightSlider .closeup-content {
        display: block !important;
      }
    }
  }

  .closeup-content {
    position: relative;
    width: 100vw;
    min-height: 578px;

    background-size: cover;
  }

  h1.closeup-title {
    font-size: 50px;
    line-height: 50px;
    top: 100px;
    left: 50%;
    margin-left: -(1140px / 2 - 150px);
    position: absolute;
    z-index: 2;

    @include widescreen {
      margin-left: -(1140px / 2);
    }
  }

  .closeup-bubble {
    position: absolute;

    left: 50%;
    top: 215px;
    padding: 25px;

    margin-left: -(1140px / 2 - 150px);

    transition: all $duration ease-in-out $text-duration;
    background: rgba($palmer-red, 0.9);

    color: white;
    @include widescreen {
      margin-left: -(1140px / 2);
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 50px 50px 0 0;
      border-color: rgba($palmer-red, 0.9) transparent transparent transparent;
      position: absolute;
      top: 0;
      right: -50px;
      transform-origin: 0 0;
    }

    h2, h3 {
      font-family: $univers-condensed-bold;
      font-size: 40px;
      line-height: 50px;
      text-transform: uppercase;
    }

    h3 {
      font-family: $univers-condensed-light;
      position: relative;
      display: inline;
      min-width: 50px;

      &:before {
        content: '';
        height: 3px;
        background: white;
        width: 40px;
        position: absolute;
        display: block;
        left: 0;
        top: -7px;
      }
    }

    p {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 30px !important;
    }

    > a {
      text-transform: uppercase;
      font-family: $univers-condensed-bold;
    }
  }

  .closeup-content {
    &.yellow {
      .closeup-bubble {
        background: rgba($palmer-yellow, 0.9);
        color: $palmer-blue;

        &:after {
          border-color: rgba($palmer-yellow, 0.9) transparent transparent transparent;
        }

        h3::before {
          background: $palmer-blue;
        }
      }
    }

    &.petrol {
      .closeup-bubble {
        background: rgba($palmer-blue, 0.9);
        color: white;

        &:after {
          border-color: rgba($palmer-blue, 0.9) transparent transparent transparent;
        }

        h3::before {
          background: white;
        }
      }
    }

    &.turquoise {
      .closeup-bubble {
        background: rgba($palmer-turquoise, 0.9);
        color: white;

        &:after {
          border-color: rgba($palmer-turquoise, 0.9) transparent transparent transparent;
        }

        h3::before {
          background: white;
        }
      }
    }

    &.sand {
      .closeup-bubble {
        background: rgba($palmer-sand, 0.9);
        color: $palmer-blue;

        &:after {
          border-color: rgba($palmer-sand, 0.9) transparent transparent transparent;
        }

        h3::before {
          background: $palmer-blue;
        }
      }
    }
  }

  .closeup-track {
    display: flex;
    margin-bottom: 30px;
    height: 137px;

    li {
      flex: none;
      position: relative;
      width: (100% / 8);

      img {
        display: block;
        width: 100%;
        height: $track-height;
        object-fit: cover;
      }

      a {
        height: 137px;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(white, 0.5);
          transition: opacity $text-duration ease-in-out;
        }

        &.active:after,
        &:hover:after {
          opacity: 0;
        }
      }
    }
  }

  .closeup-text-container {
    width: 860px;
    margin: 200px auto 50px;
    position: relative;

    h1 {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 70px;

      &:after {
        margin-top: 5px;
        content: '';
        height: 2px;
        background: white;
        width: 45px;
        display: block;
      }
    }
  }

  .is-overlay-open & {
    .closeup-bubble {
      opacity: 0;
    }
  }
}

.is-closeup-overlay {
  background: rgba($palmer-red, 0.9);

  &.yellow {
    background: rgba($palmer-yellow, 0.9);
    color: $palmer-blue;

    p {
      color: $palmer-blue;
    }

    button.close-button span {
      background: $palmer-blue;
    }

    .closeup-text-container h1::after {
      background: $palmer-blue;
    }
  }

  &.petrol {
    background: rgba($palmer-blue, 0.9);
    color: white;

    p {
      color: white;
    }

    button.close-button span {
      background: white;
    }

    .closeup-text-container h1::after {
      background: white;
    }
  }

  &.turquoise {
    background: rgba($palmer-turquoise, 0.9);
    color: white;

    p {
      color: white;
    }

    button.close-button span {
      background: white;
    }

    .closeup-text-container h1::after {
      background: white;
    }
  }

  &.sand {
    background: rgba($palmer-sand, 0.9);
    color: $palmer-blue;

    p {
      color: $palmer-blue;
    }

    button.close-button span {
      background: $palmer-blue;
    }

    .closeup-text-container h1::after {
      background: $palmer-blue;
    }
  }
a
  p {
    font-size: 20px;
    line-height: 26px;
  }
}
