%icon-button {
  background: white;
  color: $palmer-red;
  border: none;
  font-family: $univers-condensed-bold;
  font-size: (22px / 2);
  text-transform: uppercase;
  height: (56px / 2);
  line-height: (56px / 2);
  padding: 0 (32px / 2);
  text-decoration: none !important;
  margin: 0;
  vertical-align: middle;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:active, &:focus, &:hover {
    background: white;
    color: $palmer-red;
  }

  span:first-child {
    padding-top: 2px;
  }

  span[class^=icon-] {
    background-size: 100%;
    height: (48px / 2);
    width: (48px / 2);
    margin-left: 5px;
  }

  @include tablet {
    width: auto;
    font-size: (36px / 2);
    height: (82px / 2);

    span {
      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.download-button {
  @extend %icon-button;

  section.yellow & {
    background-color: $palmer-blue;
    color: white;
  }
}

.go-button {
  @extend %icon-button;

  section.yellow & {
    background-color: $palmer-blue;
    color: white;
  }

  &:after {
    display: none;
    content: '';
    //display: inline-block;
    background: url(../Images/arrow-right-red.svg);
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
    margin-right: 5px;
  }
}
