$font-univers-path: $font-base-path + '/univers';

$univers-condensed: 'Univers LT 57 Condensed Regular';
@font-face {
	font-family: $univers-condensed;
	src: url($font-univers-path + '/UniversLTStd-Cn.eot');
	src: url($font-univers-path + '/UniversLTStd-Cn.eot?#iefix') format('embedded-opentype'),
		 url($font-univers-path + '/UniversLTStd-Cn.woff2') format('woff2'),
		 url($font-univers-path + '/UniversLTStd-Cn.woff') format('woff'),
		 url($font-univers-path + '/UniversLTStd-Cn.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

$univers-light: 'Univers LT 45 Light Regular';
$logo-family: $univers-light;
@font-face {
	font-family: $univers-light;
	src: url($font-univers-path + '/UniversLTStd-Light.eot');
	src: url($font-univers-path + '/UniversLTStd-Light.eot?#iefix') format('embedded-opentype'),
		 url($font-univers-path + '/UniversLTStd-Light.woff2') format('woff2'),
		 url($font-univers-path + '/UniversLTStd-Light.woff') format('woff'),
		 url($font-univers-path + '/UniversLTStd-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: $univers-light;
	src: url($font-univers-path + '/UniversLTStd-Bold.eot');
	src: url($font-univers-path + '/UniversLTStd-Bold.eot?#iefix') format('embedded-opentype'),
	 	 url($font-univers-path + '/UniversLTStd-Bold.woff2') format('woff2'),
		 url($font-univers-path + '/UniversLTStd-Bold.woff') format('woff'),
		 url($font-univers-path + '/UniversLTStd-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

$univers: 'Univers LT 55 Regular';
@font-face {
	font-family: $univers;
	src: url($font-univers-path + '/UniversLTStd.eot');
	src: url($font-univers-path + '/UniversLTStd.eot?#iefix') format('embedded-opentype'),
		 url($font-univers-path + '/UniversLTStd.woff2') format('woff2'),
		 url($font-univers-path + '/UniversLTStd.woff') format('woff'),
		 url($font-univers-path + '/UniversLTStd.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

$univers-condensed-bold: 'Univers LT 67';
@font-face {
	font-family: $univers-condensed-bold;
	src: url($font-univers-path + '/UniversLTStd-BoldCn.eot');
	src: url($font-univers-path + '/UniversLTStd-BoldCn.eot?#iefix') format('embedded-opentype'),
		 url($font-univers-path + '/UniversLTStd-BoldCn.woff2') format('woff2'),
		 url($font-univers-path + '/UniversLTStd-BoldCn.woff') format('woff'),
		 url($font-univers-path + '/UniversLTStd-BoldCn.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

$univers-condensed-light: 'Univers LT 47 CondensedLT';
@font-face {
	font-family: $univers-condensed-light;
	src: url($font-univers-path + '/UniversLTStd-LightCn.eot');
	src: url($font-univers-path + '/UniversLTStd-LightCn.eot?#iefix') format('embedded-opentype'),
		 url($font-univers-path + '/UniversLTStd-LightCn.woff2') format('woff2'),
		 url($font-univers-path + '/UniversLTStd-LightCn.woff') format('woff'),
		 url($font-univers-path + '/UniversLTStd-LightCn.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
