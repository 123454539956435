.pitch-image-container {
    .neos-contentcollection {
        .pitch-person {
            margin: 0;
            padding: 0;
            float: left;
            position: relative;

            picture {
                display: block;
                line-height: 0;

                source,
                img {
                    width: 100%;
                }
            }

            &:hover {
                .info {
                    display: flex;
                }
            }

            .info {
                display: none;
                background: rgba(1, 43, 53, 0.8);
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .name {
                    font-family: $univers-condensed-bold;
                    font-size: 24px;
                    text-transform: uppercase;
                    text-align: center;
                    color: $palmer-yellow;
                }

                .department {
                    font-family: $univers-condensed-bold;
                    margin-bottom: 15px;
                }

                .info-text,
                .department {
                    text-transform: uppercase;
                    font-size: 16px;
                    color: #fff;
                    text-align: center;
                }
            }
        }
    }
}
