.is-image-grid {
  @extend .is-layout-element;

  img {
    max-width: none;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }

  > .column {
    display: flex;

    picture,
    div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      img {
        flex-grow: 1;
      }
    }
  }

  .is-vertical-layout {
    display: flex;
    flex-direction: column;
  }
}

#eapps-instagram-feed-1 {
  .eapps-instagram-feed-posts-item-overlay {
    background-color: rgba($palmer-blue, 0.9) !important;
  }
}
