@mixin layout-element($mult: 1) {
  margin-bottom: ($mult * $spaceBetweenElements) !important;

  @include tablet {
    margin-bottom: ($mult * $spaceBetweenElements-tablet) !important;
  }
}

@mixin gapless-layout-element($mult: 1) {
  margin-top: -$spaceBetweenElements;

  @include tablet {
    margin-top: -$spaceBetweenElements-tablet !important;
  }
}

.is-full-image-container {
  @extend .is-layout-element;
  line-height: 0;
  img {
    width: 100%;
  }
}

.is-layout-element {
  @include layout-element;
}

.is-gapless-layout-element {
  @include gapless-layout-element;
}

.is-text-container {
  padding: 0 retina(66px);

  @include tablet {
    padding: 0 retina(214px);
    max-width: (retina(1205px) + retina(214px));
  }

  @include desktop {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 242px 0 0;
  }
}

.is-full-text-container {
  @extend .is-text-container;

  @include tablet {
    max-width: none;
  }

  @include desktop {
    padding: 0;
    max-width: 1140px;
  }
}

.text-container {
  @extend .is-text-container;

  .column {
    .text-container {
      padding-left: 0;

      @include desktop {
        padding-right: 0;
      }
    }
  }

  &.is-gapless {
    padding: 0;
    max-width: none;
  }
}

.full-text-container {
  @extend .is-full-text-container;
}

.container {
  max-width: 1140px;
  margin-left: auto !important;
  margin-right: auto !important;

  footer & {
    max-width: 1440px;
  }
}

.elancer-palmer-textgridtwocolumns {
  padding-right: 0;

  @include tablet {
    display: flex;
    padding-right: 107px;
    max-width: none;
  }

  @include desktop {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0;
  }

  .column {
    padding: 0;
    width: 100%;

    @include desktop {
      width: 50%;
    }
  }

  .column {
    display: flex;
    align-items: flex-start;

    .neos-contentcollection {
      flex-grow: 1;

      @include tablet {
        width: 100%;
      }
    }
  }

  blockquote {
    margin-left: -33px;

    @include tablet {
      margin: 0 40px;
    }
  }

  .elancer-palmer-video,
  .is-full-image-container {
    padding: 0;
    margin-left: -33px;

    @include tablet {
      margin-left: 0;
      max-width: 100% !important;
    }
  }
}

main.is-cannes-page {
  background: -webkit-gradient(linear, left top, left bottom, from($palmer-red), to($palmer-red-dark));
  background: -webkit-linear-gradient(top, $palmer-red 0%, $palmer-red-dark 100%);
  background: -o-linear-gradient(top, $palmer-red 0%, $palmer-red-dark 100%);
  background: linear-gradient(to bottom, $palmer-red 0%, $palmer-red-dark 100%);
  color: white;
}

.full-height {
    height: 100vh;
}
