body.is-overlay-open {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
}

.is-overlay {

  .full-text-container {
    position: relative;
  }

  p, ul, ol {
    margin-bottom: 1em !important;
  }

  $overlayDuration: 0.5s;

  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  display: none;
  background: rgba($palmer-red, 0.9);
  color: white;
  transform: translateY(100vh);
  transition: all $overlayDuration ease-in-out;
  z-index: 1001;
  padding: retina(50px) 0;

  @include neos-navigate-panel {
    left: $neos-navigate-panel-width;
    padding-left: 20px;
  }

  @include neos-inspector-panel {
    right: $neos-inspector-panel-width;
    padding-right: 50px;
  }

  @include neos-controls {
    top: $neos-control-height;
    padding-top: 60px;
  }

  @include desktop {
    padding-left: 150px;
    padding-right: 150px;
  }

  &.no-transition {
    transition: none;
  }

  &.will-open {
    display: block;
  }

  &.is-open {
    transform: translateY(0);
  }

  h1 {
    margin-bottom: (44px / 2);
  }

  h2 {
    font-family: $univers-light;
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  p, h2, .typo3-neos-nodetypes-text ul {
    margin-bottom: (50px / 2);
    font-size: (20px / 2);
    line-height: (30px / 2);
    color: white;

    &:last-child {
      margin-bottom: 0;
    }

    @include tablet {
      font-size: 16px;
      line-height: 22px;
    }
  }

  button.close-button {
    $lineHeight: 2px;
    $size: 25px;
    position: absolute;
    cursor: pointer;
    right: retina(66px);
    top: 1px;
    padding: 0;
    width: $size;
    height: $size;
    border: 0;
    background: transparent;

    @include tablet {
      top: 9px;
      right: 107px;
    }

    @include desktop {
      right: 0;
      top: 32px;
    }

    @include neos-backend {
      right: -40px;
      top: 0;
    }

    span {
      width: $size;
      height: $lineHeight;
      display: block;
      background: white;
      border-radius: 100px;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: translateY(-$lineHeight) rotate(-45deg);
      }

    }
  }

  .contact {
    text-transform: none;

    p {
      font-size: (28px / 2);
      line-height: (38px / 2);
      @include layout-element(0.20);

      &:last-child {
        margin-bottom: 0 !important;
        line-height: 0;
      }

      @include desktop {
        font-size: 18px;
        line-height: 24px;
      }
    }

    img {
      max-width: 40vw;
    }

    a {
      text-decoration: none;

      &:hover {
        font-weight: bold;
      }
    }

    a.download-link {
      position: relative;
      padding-left: .7em;
      display: block;
      margin-top: -3px;


      &:before {
        content: '';
        height: 10px;
        background: url(../Images/arrow-right.svg) left center no-repeat;
        width: 10px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -5px;
      }
    }

    @include tablet {
      padding-left: 240px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: auto;
        width: 220px;
        object-fit: cover;
      }
    }

  }

  .social {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -2px;
    min-width: 50px;
    padding-top: 3px;

    @include tablet {
      padding-top: 0;
    }

    > * {
      margin: 0 2px;
    }
  }
}

.is-overlay .article-list {
  h2 {
    @include desktop {
      font-size: 26px;
      line-height: 30px;
      padding: 15px 30px 15px 0;
    }
  }

  a:after {
    width: 20px;
    height: 20px;
  }
}

#imprint {
  p, h1, h2 {
    @include layout-element(0.25);


  }
}
