section.red {
  background: $palmer-red;
  color: white;
  padding-top: $spaceBetweenElements;
  padding-bottom: $spaceBetweenElements;

  @include neos-controls {
    padding-left: 10px;
    padding-right: 10px;
  }
}

section.turquoise {
  background: $palmer-turquoise;
  color: $palmer-blue;
  padding-top: $spaceBetweenElements;
  padding-bottom: $spaceBetweenElements;

  @include neos-controls {
    padding-left: 10px;
    padding-right: 10px;
  }
}

section.yellow {
  background: $palmer-yellow;
  color: $palmer-blue;
  padding-top: $spaceBetweenElements;
  padding-bottom: $spaceBetweenElements;

  @include neos-controls {
    padding-left: 10px;
    padding-right: 10px;
  }
}



.is-image-grid + section.red {
  @extend .is-gapless-layout-element;
}

section.red + .is-image-grid {
  @extend .is-gapless-layout-element;
}


#jobs{
  margin-top: -35px !important;
}

@media (min-width: 769px) {
  #jobs{
    margin-top: -77px !important;
  }
}
