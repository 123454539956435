.is-bulk {
  background: url('../Images/bulk-background@2x.png') $palmer-red;
  background-size: cover;
  flex-grow: 1;
  display: flex;

  > .logo {
    position: absolute;
    top: retina(35px);
    right: retina(35px);
    width: retina(121px);

    @include tablet {
      top: retina(42px);
      right: retina(42px);
      width: retina(134px);
    }

    @include desktop {
      top: 37px;
      right: 30px;
      width: 120px;
    }
  }

  > main {
    padding: retina(65px);
    display: flex;
    flex-direction: column;

    @include tablet {
      padding: retina(186px) retina(386px);
    }

    @include desktop {
      padding: 103px 0;
      width: 812px;
      margin: 0 auto;
    }
  }

  .is-full-height-centered {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }

  .with-text {
    color: white;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: ((9 / 16) * 100) * 1%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.is-video {
    main {
      width: 100%;

      @include tablet {
        padding: retina(186px) retina(386px);
      }

      @include desktop {
        padding: 103px 0;
        width: 812px;
        margin: 0 auto;
      }
    }
  }

  aside {
    text-align: center;

    @include tablet {
      text-align: left;
    }

    h2 {
      color: white;
      font-family: $univers-condensed-bold;
      text-transform: none;
      font-size: retina(30px);
      line-height: retina(40px);

      @include tablet {
        font-size: retina(42px);
        line-height: retina(57px);
      }

      @include desktop {
        font-size: 30px;
        line-height: 40px;
      }
    }

    a, span {
      background: #ce1141;
      display: block;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      font-size: retina(18px);
      line-height: retina(39px);

      @include tablet {
        display: inline-block;
        padding: 0 retina(225px);
        font-size: retina(26px);
        line-height: retina(56px);
      }

      @include desktop {
        font-size: 18px;
        line-height: 39px;
      }

      &:after {
        content: '';
        background: url('../Images/arrow-right.svg') no-repeat;
        width: retina(14px);
        height: retina(25px);
        display: inline-block;
        vertical-align: middle;
        margin-left: retina(20px);
        position: relative;

        @include desktop {
          width: 14px;
          height: 25px;
          top: -2px;
        }
      }
    }
  }
}