html {
    overflow: visible;
}

body.is-menu-open {
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
}

.palmer {
    $logoDuration: 0.5s;

    pointer-events: all;
    font-size: (30px / 2);
    line-height: (36px / 2);
    text-transform: uppercase;
    font-family: $logo-family;
    font-weight: bold;

    position: absolute;
    right: 7px;
    top: 14px;
    text-align: right;
    transition: all $logoDuration ease;

    @include tablet {
        top: (42px / 2);
        right: (42px / 2);
        color: transparent;
        overflow: hidden;
        width: (134px / 2);
        height: (134px / 2);
        background: url(../Images/logo.svg);
        background-size: 100% 100%;

        &:hover, &:focus, &:active {
            color: transparent !important;
        }

        &.is-cannes-page {
            border: 1px solid white;
        }
    }

    @include desktop {
        width: 120px;
        height: 120px;
        right: 30px;
        top: 37px;
        padding: 10px;
        font-size: 14px;
        line-height: 14px;
    }
}

.menu {
    background: $palmer-red;

    color: white;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    pointer-events: none;

    $menuHeight: retina(118px);
    $linksCount: 6;

    z-index: 1000;
    height: $menuHeight;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    $linkDuration: 0.5s; // Animation time of one link animation
    $linkDelay: 0.05s; // Delay until next link starts
    $buttonDuration: 0.5s;
    $languageDuration: 0.5s;
    $logoDuration: 0.5s;

    $backgroundDuration-mobile: 0.3s; // Duration for background animation (mobile)
    $backgroundDuration-tablet: 0.5s; // Duration for background animation (tablet)

    $backgroundDelay-mobile: $linksCount * 0.1s; // Delay until background animation starts closing (mobile)
    $backgroundDelay-tablet: $linksCount * $linkDelay; // Delay until background animation starts closing (tablet)

    $linkStartDelay-mobile: 0.2s; // Delay unitil links start floating in after background animation (mobile)
    $linkStartDelay-tablet: 0.3s; // Delay unitil links start floating in after background animation (tablet)

    transition: all $backgroundDuration-mobile ease $backgroundDelay-mobile;

    @include tablet {
        background: transparent;
        height: (218px / 2);

        .palmer {
            position: absolute;
        }

        &:after {
            content: '';
            z-index: -1;
            display: block;
            position: fixed;
            top: 0;
            left: -130vw;
            width: 130vw;
            height: 100vh;
            overflow: auto;
            background: url('../Images/menu-background.svg') no-repeat;
            background-size: 100% 100% !important;
            visibility: hidden;

            transition: all $backgroundDuration-tablet ease $backgroundDelay-tablet;
        }
    }

    @include desktop {

        /*
        position: absolute;

        button {
          position: fixed !important;
        }
        */
    }

    > .column {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.is-isOneMagnify {
        button {
            @include tablet {
                background: $onemagnify-red;
            }
        }
    }

    button {
        pointer-events: all;
        background: transparent;
        border: none;
        display: block;
        padding: 0;
        position: absolute;
        left: 13px;
        top: 17px;
        transition: all $buttonDuration ease;
        cursor: pointer;

        @include tablet {
            background: $palmer-red;
            padding: (30px / 2);
            top: (42px / 2);
            left: (42px / 2);
            height: 48px;
            width: 51px;
        }

        @include desktop {
            top: 37px;
            left: 30px;
        }

        span {
            display: block;
            background: white;
            height: retina(8px);
            margin-bottom: retina(13px);
            transition: all $buttonDuration ease;
            width: retina(55px);

            @include tablet {
                height: retina(6px);
                width: retina(42px);
                margin-bottom: retina(6px);
            }

            &:last-child {
                margin-bottom: 0;
            }

        }
    }

    nav {
        display: flex;
        visibility: hidden;
        height: 100vh;

        overflow-x: hidden;
        overflow-y: scroll;

        flex-direction: column;
        //justify-content: center;
        align-items: center;

        ul {
            a {
                color: white;
                text-transform: uppercase;
                font-size: (44px / 2);

                @include tablet {
                    font-size: (50px / 2);
                }

                @include desktop {
                    font-size: 36px;
                }
            }

            li.active a {
                font-weight: bold;
            }

        }

        div.main {
            flex-grow: 1;
            display: flex;
            overflow: auto;
            width: 100vw;

            justify-content: center;

            ul {
                margin: auto 0;
                padding: 0;

                a {
                    display: block;
                    text-align: center;
                    height: 60px;
                    line-height: 60px;
                    transition: all $linkDuration ease;
                    opacity: 0;
                    transform: translate(-50vw, 0);

                    @include tablet {
                        font-size: (54px / 2);
                        height: (164px / 2);
                        line-height: (164px / 2);
                    }

                    @include desktop {
                        height: 90px;
                        line-height: 90px;
                        font-size: 40px;
                    }
                }

                li {
                    @for $i from 0 through $linksCount {
                        &:nth-child(#{$i}) a {
                            transition-delay: (($linksCount - $i) * $linkDelay);
                        }
                    }
                }
            }
        }

        ul.language {
            text-align: center;
            transition: all $languageDuration ease;
            opacity: 0;
            pointer-events: none;

            margin-top: (92px / 2);

            @include tablet {
                margin-top: (70px / 2);
            }

            @include desktop {
                margin-top: 51px;
            }

            li {
                pointer-events: auto;
                display: inline-block;

                &:after {
                    content: '';
                    position: relative;
                    height: 16px;
                    width: 8px;
                    left: 3px;
                    font-size: 0;

                    border-left: 1px solid white;
                    display: inline-block;

                    @include tablet {
                        height: 18px;
                        width: 10px;
                        border-left-width: 2px;
                    }

                    @include desktop {
                        height: 26px;
                        width: 13px;
                        border-left-width: 2px;
                    }
                }

                &:last-child:after {
                    display: none;
                }
            }
        }
    }

    .is-overlay-open & {
        nav {
            visibility: visible;
        }
    }

    .is-menu-open & {

        height: 100vh;
        z-index: 2000;
        pointer-events: all;
        background-color: rgba($palmer-red, 0.9);
        transition-delay: 0s;
        position: fixed;

        @include tablet {
            height: (218px / 2);
            background-color: transparent;

            &:after {
                visibility: visible;
                left: 0;
                transition-delay: 0s;
            }
        }

        .palmer {
            opacity: 0;
            transform: translate(0, 10px);

            @include tablet {
                opacity: 1;
                transform: none;
                z-index: -1;
            }

            @include desktop {
                display: none;
            }
        }

        button {

            transform: translate((36px / 2 - 13px), (-10px + (76px / 2))) scale(1.5);

            @include tablet {
                background-color: transparent;
                transform: translate((-35px + (60px / 2)), (-33px + (56px / 2))) scale(1.5);
            }

            span {
                border-radius: 100px;
                height: 2px;
            }

            span:nth-child(1) {
                transform: translate(0, 9px) rotate(45deg);

                @include tablet {
                    transform: translate(0, 5px) rotate(45deg);
                }
            }

            span:nth-child(3) {
                transform: translate(0, -9px) rotate(-45deg);

                @include tablet {
                    transform: translate(0, -5px) rotate(-45deg);
                }
            }

            span:nth-child(2) {
                opacity: 0;
            }
        }

        nav {
            visibility: visible;

            ul.language {
                transform: translate(0, 0);
                opacity: 1;
            }

            div.main li {

                a {
                    opacity: 1;
                    transform: translate(0, 0);
                }

                @for $i from 0 through $linksCount {
                    &:nth-child(#{$i}) a {
                        transition-delay: ($linkStartDelay-mobile + $i * $linkDelay);
                    }
                }

                @include tablet {
                    @if $linkStartDelay-mobile != $linkStartDelay-tablet {
                        @for $i from 0 through $linksCount {
                            &:nth-child(#{$i}) a {
                                transition-delay: ($linkStartDelay-tablet + $i * $linkDelay);
                            }
                        }
                    }
                }
            }
        }
    }

    & + .palmer {
        z-index: 9999;

        &.logo-ph-onemagnify {
            padding: 0;
        }
    }

    > .language {
        display: flex;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: all;

        @include tablet {
            top: (42px / 2);
            left: (42px / 2);
            transform: none;
        }

        @include desktop {
            top: 37px;
            left: 30px;
        }

        li {
            padding-right: 0.75rem;
            cursor: pointer;
            font-family: "Univers LT 67";

            &:last-child {
                order: -1;
            }

            @include tablet {
                background: $palmer-red;
                padding: 14px 15px;
                height: 48px;
                width: 51px;
                margin-right: 0.25rem;
            }

            &.current,
            &:hover {
                @include tablet {
                    background: #fff;
                    color: $palmer-red;
                }

                a {
                    text-decoration: underline;

                    @include tablet {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.is-menu-open {
    .mobile-ph-onemagnify-logo {
        opacity: 0;
    }
}

.mobile-ph-onemagnify-logo {
    z-index: 999;
    position: absolute;
    height: 100%;
    right: 0;
    float: right;
    pointer-events: all;
    opacity: 1;
    transition-delay: 1.5s;
    transition: all 1.5s ease;

    img {
        display: block;
        position: relative;
        max-height: 58px;
        width: auto;
        right: 0;
    }
}

@media screen and (max-width: 768px) {
    .menu {
        &.is-isOneMagnify {
            .is-menu-open & {
                background-color: rgba($onemagnify-red, 0.9);
            }
        }
    }
}

body > .palmer {
    z-index: 3;
}

.is-onemagnify-page {
    nav.sub-nav {
        background: $onemagnify-red;
    }
}

nav.sub-nav {
    @extend .is-gapless-layout-element;
    @extend .is-layout-element;

    @include neos-navigate-panel {
        padding-left: 10px;
    }

    $height: 30px;

    .hero + &,
    &.project-filter {
        position: relative;
        margin-top: (-$spaceBetweenElements-desktop - $height) !important;
    }

    display: none;
    background: $palmer-red;
    height: $height;

    font-size: 16px;
    font-family: $univers-condensed-light;

    ul {
        @extend .is-text-container;
    }

    li {
        padding: 0 25px;
        display: inline-block;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &.is-breadcrumb {
        li {
            padding: 0;

            &:after {
                content: '';
                border-right: 1px solid white;
                padding-right: 12px;
                margin-right: 11px;
                position: relative;
                top: 4px;
                height: 20px;
                display: inline-block;
            }

            &:last-child:after {
                display: none;
            }
        }
    }

    a, span {
        text-transform: uppercase;
        color: white;
        line-height: 30px;
    }

    @include desktop {
        display: block;
    }


}
