div.filter {
  display: flex;
  justify-content: center;
  margin-bottom: (36px / 2);

  select {
    font-family: $univers-condensed-bold;
    font-size: (44px / 2);
    line-height: (44px / 2);
    color: $palmer-red;
    text-align: center;
    appearance: none;
    border: none;
    background: transparent;
    box-sizing: content-box;
    padding-right: 13px;
  }

  &:after {
    content: '';
    background: url(../Images/arrow-right-red.svg) left center no-repeat;
    height: 22px;
    width: 13px;
    margin-left: -13px;
    pointer-events: none;
    transform: translate(5px, 0) scale(0.6) rotate(90deg);
  }
}
