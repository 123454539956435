.elancer-palmer-bloglist {
  @include layout-element(1.5);

  .load-more-container {
    display: flex;
    justify-content: center;

    color: $palmer-red;
    svg path {
      stroke: $palmer-red;
    }

    .load-more {
      transition: all 0.5s ease;
      &.is-loading {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  @include tablet-only {
    width: 810px;
  }
}

.blog-list {

  $duration: 0.5s;

  li {
    padding: 0 retina(66px);
  }

  @include tablet {
    display: flex;
    flex-wrap: wrap;
    margin-left: -9px;
    margin-right: -9px;

    li {
      flex: none;
      width: ((810px + 2 * 9px) / 3);
      padding: 0 9px;
    }
  }

  @include desktop {
    li {
      width: (100% / 3);
    }
  }

  article {
    margin-bottom: (70px / 2);

    @include desktop {
      &:hover {
        .blog-list-image {
          span {
            opacity: 1;
          }

          &:before {
            opacity: 1;
          }
        }
      }
    }

    .blog-list-image {
      position: relative;
      cursor: pointer;

      span {
        color: white;
        position: absolute;
        left: 10px;
        bottom: 10px;
        opacity: 0;
        transition: opacity $duration;

        font-family: $univers-condensed;
        font-size: (24px / 2);
        line-height: (34px / 2);

        @include tablet {
          font-size: (36px / 2);
          line-height: (46px / 2);
        }

        @include desktop {
          font-size: 30px;
          line-height: 40px;
        }
      }
      &:before {
        content: '';
        display: block;
        background: rgba($palmer-blue, 0.8);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: opacity $duration;
        pointer-events: none;
      }
    }

    &.is-palmer .blog-list-image:before {
      background: rgba($palmer-red, 0.8);
    }

    picture {
      display: block;
      margin-bottom: (22px / 2);

      img {
        display: block;
        width: 100%;
      }
    }

    h2 {
      color: $palmer-red;
      text-transform: uppercase;
      font-family: $univers-condensed-bold;
      font-size: retina(34px);
      line-height: retina(36px);
      margin-bottom: 4px;

      @include desktop {
        font-size: 19px;
        line-height: 22px;
      }
    }

    h3 {
      font-family: $univers-light;
      font-weight: bold;
      font-size: retina(22px);
      line-height: retina(22px);
      margin-bottom: 5px;

      @include tablet {
        font-size: retina(24px);
        line-height: retina(24px);
      }

      @include desktop {
        font-size: 13px;
      }
    }

    p {
      font-size: (22px / 2);
      line-height: (32px / 2);
      margin-bottom: 8px !important;

      @include tablet {
        font-size: retina(24px);
      }

      @include desktop {
        font-size: 14px;
        line-height: 18px;
      }
    }

    a {
      display: block;
      font-family: $univers-condensed-bold;
      text-transform: uppercase;
      font-size: retina(22px);
      line-height: retina(55px);
      background: $palmer-red;
      color: white;
      text-align: center;


      @include tablet {
        font-size: retina(34px);
        line-height: retina(63px);
      }

      @include desktop {
        font-size: 12px;
        line-height: 25px;
        display: inline-block;
        padding: 0 12px;
      }
    }
  }
}

.blog-info {
  padding: 0 (34px / 2);
  position: absolute;
  width: 100%;
  bottom: 0;

  &:before {
    content: '';
    background: rgba($palmer-red, 0.7);
    width: 15px;
    height: 52px;
    float: left;
  }

  .role, .author {
    font-size: (32px / 2);
    background: rgba($palmer-red, 0.7);
    margin-left: 15px;
    padding-left: 12px;
    height: retina(52px);
    line-height: retina(52px);
  }

  .role {
    color: white;
  }

  .author {
    background: white;
  }

  @include tablet {
    display: inline-flex;
    flex-direction: column;
    z-index: 5;
    padding: 0 107px;
    margin-left: -14px;
    width: auto;

    &:before {
      margin-left: -9px;
      width: 9px;
      height: (23px + 52px);
      position: absolute;
      bottom: 0;

    }

    .role, .author {
      font-size: (34px / 2);
      margin: 0;
    }

    .role {
      padding-top: 0;
      padding-left: 0;
      padding-right: retina(112px);
      height: retina(46px);
      line-height: retina(46px);
    }

    .author {
      font-size: (58px / 2);
      line-height: 52px;
      padding: 0 14px;
      height: auto;
    }
  }

  @include desktop {
    padding: 0;
    left: 50%;
    margin-left: (-440px + 160px) - 48px;

    .author {
      left: 0;
    }
  }
}

.blog-nav {
  position: relative;
  margin: 0 retina(66px);

  @include tablet {
    margin: 0 retina(384px);
  }

  @include desktop {
    width: 880px;
    margin: 0 auto;
  }

  &:last-child {
    @include layout-element(2);
  }

  h1 {
    color: $palmer-red;
    font-size: retina(36px);
    line-height: 22px;
    padding: 0;

    @include tablet {
      font-size: retina(58px);
      line-height: 32px;
    }

    @include desktop {
      padding-left: 160px;
    }
  }

  > a,
  > span {
    display: block;
    position: absolute;
    left: retina(-48px);

    @include tablet {
      left: retina(-172px);
    }

    @include desktop {
      left: -(1140px - 880px) / 2 ;
    }

    span {
      display: block;
      width: retina(48px);
      height: retina(48px);
      background: url(../Images/arrow-right.svg) center center no-repeat #8a8b8c;
      background-size: retina(26px) retina(26px);
      transform: scaleX(-1);

      @include tablet {
        width: retina(72px);
        height: retina(72px);
      }
    }

    &.next {
      left: auto;
      right: retina(-48px);
      span {
        transform: none;
      }
      @include tablet {
        right: retina(-172px);
      }

      @include desktop {
        right: -(1140px - 880px) / 2;
      }
    }
  }


  > a {
    span {
      background-color: $palmer-red;
    }
  }
}

blockquote {
  @extend .is-layout-element;

  color: $palmer-red;
  text-align: center;
  border-top: 3px double $palmer-blue;
  border-bottom: 3px double $palmer-blue;
  padding: retina(44px) 0 !important;
  position: relative;

  p {
    position: relative;
    max-width: retina(460px);
    margin: 0 auto !important;
    &:before, &:after {
      content: '“';
      color: $palmer-blue;
      font-family: Arial;
      display: block;
      font-size: (72px / 2);
      line-height: (72px / 2);
      position: absolute;
      left: (-54px / 2);
      top: (-22px / 2);
    }

    &:after {
      content: '”';
      top: auto;
      bottom: auto;
      left: auto;
      right: (-54px / 2);
    }
  }

  @include tablet {
    padding: 40px 0 !important;

    p {
      font-size: (34px / 2);
      line-height: (44px / 2);
      max-width: (792px / 2);

      &:before, &:after {
        font-size: (100px / 2);
      }
    }
  }

  @include desktop {
    p {
      font-size: 24px;
      line-height: 36px;
      max-width: 550px;

      &:before, &:after {
        font-size: 72px;
      }
    }
  }
}

.blog-share {

  h2 {
    font-family: $univers-condensed-bold;
    text-transform: uppercase;

    @include tablet {
      font-size: retina(34px);
      line-height: retina(34px);
    }
  }
  ul {
    li {
      display: inline-block;
    }
  }
}

.blog-author {

  &:before {
    content: '';
    border-top: 1px solid $palmer-blue;
    display: block;
    margin-bottom: 20px;
  }

  @include clearfix;

  picture {
    display: block;
    margin-bottom: 10px;
    img {
      display: block;
    }

    @include tablet {
      float: left;
      margin-right: (114px / 2);
    }
  }

  p {
    font-size: (24px / 2);
    line-height: (34px / 2);
    margin-bottom: 10px !important;

    @include tablet {
      font-size: (34px / 2);
      line-height:( 44px / 2);
    }
  }

  h2 {
    text-transform: none;
    color: $palmer-red;
    font-size: retina(34px);
    line-height: retina(44px);
    display: block;

    @include tablet {
      font-size: retina(58px);
      line-height: retina(58px);
      margin-bottom: 4px;
    }
  }

  ul li {
    display: inline-block;
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
  }

  @include desktop {
    $width: 200px;
    $content-width: 118px;

    position: absolute;
    top: 0;
    left: 50%;
    text-align: right;
    width: $width;
    margin-left: -(880px) / 2 + 160px - $width - 48px;

    .full-text-container {
      padding: 0 !important;
      width: 100% !important;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      picture {
        margin: 0 0 5px;
      }

      h2 {
        font-size: 16px;
        line-height: 20px;
        text-decoration: none;
        width: $content-width;
        order: 1;
        display: block;
        margin-bottom: 10px;
      }

      p {
        font-size: 13px !important;
        line-height: 1.3em !important;
        order: 2;
      }

      ul {
        width: $content-width;
        font-size: 0;

        span {
          width: 28px;
          height: 28px;
          background-size: 100% 100%;
        }
      }
    }



    &:before {
      display: none;
    }

    picture {
      float: none;
    }

    h1 {
      display: none;
    }
  }
}

.blog-related-header {
  font-size: (32px /2);
  color: $palmer-red;
  display: flex;
  align-items: center;

  @include tablet {
    font-size: (34px / 2);
  }

  &:before, &:after {
    content: '';
    border-top: 3px double $palmer-blue;
    position: relative;
    top: -2px
  }

  &:before {
    flex: none;
    width: (66px / 2);
    margin-right: 3px;

    @include tablet {
      width: (384px / 2);
    }
  }

  &:after {
    flex-grow: 1;
    margin-left: 3px;
  }
}

.blog-related-list {

  &:last-child {
    @include layout-element;
  }

  @include tablet {

  }

  .is-link,
  .has-image {

    flex: none;
    width: 50%;

    @include tablet {
      width: 20%;
      height: 20vw;
      margin: 0 !important;
      overflow: hidden;


      picture img {
        object-fit: cover;
      }
    }

    &:nth-child(3) {
      @include tablet {
        order: 3;
      }
    }

    &:nth-child(4) {
      display: none;

      @include tablet {
        display: flex;
        order: 5;
      }
    }
  }

  a.is-link {
    @include tablet {
      margin: 0 !important;
    }
  }

  .is-link {
    span:before {
      content: '';
      display: block;
      background: url('../Images/blog.svg');
      width: 27px;
      height: 27px;
      margin: 0 auto 10px;
    }

    div:after {
      display: none;
    }
    @include tablet {
      height: 20vw !important;
      margin: 0 !important;
      div {
        height: 20vw;
        &:after {
          display: none;
        }
      }
    }
  }
}

.blog-article {
  position: relative;

  > .neos-contentcollection {

    @include desktop {
      min-height: (300px + 77px);

      > *:first-child {
        //min-height: 300px;
      }
    }
  }

  .full-text-container {
    @include layout-element;
    padding: 0 retina(65px);

    ol {
      list-style: none;
      counter-reset: list;
      $offset: 20px;
      margin-left: $offset;
      position: relative;

      li {

        &:before {
          counter-increment: list;
          content: counter(list) ":";
          font-family: $univers-condensed-bold;
          display: block;
          position: absolute;
          left: -$offset;
        }

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    ul {
      list-style: square;

      li {
        &:before {
          content: none;
        }

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    p {
      margin-bottom: 1em !important;
      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    @include tablet {
      padding: 0 retina(384px);
    }

    @include desktop {
      width: 880px;
      max-width: none;

      padding: 0 148px 0 160px;

      p, li {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .elancer-palmer-mediumimage {
    padding-left: 0;
    padding-right: 0;
  }
}

.hero.has-blog-info {
  overflow: visible;
}
