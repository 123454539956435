.elancer-palmer-cannesheader {
  background-image: url(../Images/cannes-visual.png);
  background-position: 80% -90px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding-top: 0.5em;
  min-height: 400px;
  margin-bottom: -80px;

  @include tablet {
    background-position: 70% -200px;
    min-height: 500px;
    margin-bottom: 0;
    padding-top: 2.5em;
    background-size: auto 600px;
  }

  @include desktop {
    padding-top: 3.5em;
    background-position: 60% -270px;
    min-height: 600px;
    background-size: auto 800px;
  }

  .full-text-container {
    padding: 0 5%;

    @include tablet {
      padding: 0 25%;
    }

    @include desktop {
      padding: 0 20%;
    }

    @include widescreen {
      padding: 0 15%;
    }
  }

  h1 {
    margin-bottom: 0;
    font-size: 1.8em;
    line-height: 1.1;

    @include tablet {
      font-size: 2em;
    }
  }

  h3 {
    font-size: 0.9em;
    text-transform: uppercase;
  }

  h4 {
    font-size: 0.95em;

    @include tablet {
      font-size: 1em;
    }
  }

  table {
    border: 0;
    margin-top: 2em;
    width: auto;

    tr {
      td {
        font-weight: 400;
        font-family: "Univers LT 67";

        &:first-child {
          padding-right: 1em;
        }
      }
    }
  }
}

.is-cannes-page {
  .text-container {
    @extend .is-full-text-container;
    text-align: center;
  }

  #cannes-form {
    @extend .is-full-text-container;
    @include layout-element(2);
    padding: 0 15%;

    @include tablet {
      padding: 0 25%;
    }

    @include desktop {
      padding: 0 20%;
    }

    @include widescreen {
      padding: 0 15%;
    }
  }
}

#cannes-form {

  fieldset {
    border: 0;

    label {
      display: none;

      &.checkbox {
        display: block;
        padding-left: 30px;
        margin-top: 2em;
        margin-bottom: 1em;
      }
    }

    > div {
      margin-bottom: 10px;
      position: relative;

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        display: none;
      }
    }
  }

  input[type=text] {
    width: 100%;
    background: transparent;
    border: 1px solid white;
    font-size: 1em;
    padding: 4px 7px 3px;
    color: white;
    font-family: $univers-condensed-light;

    @include placeholder {
      color: white;
      text-transform: uppercase;
      font-family: $univers-condensed-light;
    }
  }

  input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked {
      ~ .checkmark {
        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;

    &:after {
      content: "";
      display: none;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background: url(../Images/checkmark.png) center center no-repeat;
      background-size: cover;
    }
  }

  .submit {
    button {
      display: block;
      width: 100%;
      background: white;
      color: $palmer-red;
      text-transform: uppercase;
      font-family: $univers-condensed-bold;
      font-size: 1em;
      border: none;
      padding: 5px 0;
    }
  }

  .notice {
    font-size: 0.9em;
  }

  .error-message {
    font-family: $univers-condensed-bold;
    background: url(../Images/error.png) top left no-repeat;
    padding-left: 2em;
    background-size: 22px;
    line-height: 1.5;

    @include desktop {
      background: url(../Images/error.png) 0 7px no-repeat;
      background-size: 22px;
    }
  }
}
