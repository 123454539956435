.hero {
  .bubble-info {
    background: rgba($palmer-red, 0.7);
    color: white;
    position: absolute !important;
    bottom: 0;
    width: 100%;
    padding: 6px 33px;
    z-index: 2;

    @include tablet {
      left: (214px / 2);
      width: (820px / 2);
      padding: (32px / 2);
      bottom: (348px / 2);
      background: rgba($palmer-red, 0.9);
    }

    @include desktop {
      left: 50%;
      margin-left: (-1140px / 2);
    }

    h1 {
      font-size: (32px / 2);
      line-height: (32px / 2);
      margin-bottom: 4px;
      // 2022-02 Tobias Blog Uebersicht falsche Umbrueche
      // hyphens: auto;
      // word-break: break-word;

      @include tablet {
        font-size: (58px / 2);
        line-height: (72px / 2);
        &:after {
          content: '';
          display: block;
          width: 40px;
          height: 3px;
          background: white;
          margin: 3px 0 14px;
        }
      }
    }

    h2 {
      font-family: $univers-light;
      font-weight: bold;
      font-size: (18px / 2);
      line-height: (18px / 2);
      margin: 0 0 4px;
      text-transform: none;

      @include tablet {
        font-weight: normal;
        font-size: (34px / 2);
        margin-bottom: 18px;
      }
    }

    p {
      font-size: (22px / 2);
      line-height: (28px / 2);
      margin-bottom: 4px !important;

      @include tablet {
        font-size: (34px / 2);
        line-height: (44px / 2);
        margin-bottom: 18px !important;
      }
    }

    a {
      font-size: (22px / 2);
      line-height: (22px / 2);
      font-family: $univers-condensed-bold;
      text-transform: uppercase;

      @include tablet {
        font-size: (34px / 2);
      }
    }
  }
}
