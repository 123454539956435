$neos-control-height: 82px;
$neos-navigate-panel-width: 321px;
$neos-inspector-panel-width: 320px;

@mixin neos-backend($base: false) {
  @if $base {
    .neos-backend {
      @content;
    }
  } @else {
    .neos-backend & {
      @content;
    }
  }
}

@mixin neos-controls($base: false) {
  @if $base {
    .neos-controls:not(.neos-full-screen) {
      @content;
    }
  } @else {
    .neos-controls:not(.neos-full-screen) & {
      @content;
    }
  }
}

@mixin neos-navigate-panel($base: false) {
  @if $base {
    .neos-navigate-panel-open:not(.neos-full-screen) {
      @content;
    }
  } @else {
    .neos-navigate-panel-open:not(.neos-full-screen) & {
      @content;
    }
  }
}

@mixin neos-inspector-panel($base: false) {
  @if $base {
    .neos-inspector-panel-open:not(.neos-full-screen) {
      @content;
    }
  } @else {
    .neos-inspector-panel-open:not(.neos-full-screen) & {
      @content;
    }
  }
}