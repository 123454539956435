.hero {

  $scale-duration: 1.5s;
  $fade-duration: 0.2s;
  $padding-mobile: (64px / 2);
  $padding-tablet: (214px / 2);
  $container-mobile: 256px;
  $container-tablet: 810px;
  $container-desktop: 1140px;

  @extend .is-layout-element;

  position: relative;
  overflow: hidden;


  &.is-white {
    color: white;

    svg path {
      stroke: white !important;
    }

    .first {
      svg path.st0 {
        stroke: $palmer-blue !important;
      }
    }
  }

  &.has-mouseover {
    .hero-background {
      transform: scale(1.1);
      &:before {
        opacity: 1;
      }


      @include neos-backend {
        transform: none;
        &:before {
          opacity: 0;
        }
      }
    }
  }

  picture {
    img {
      width: 100vw;
      object-fit: cover;
      display: block;
    }
  }

  video {
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    max-width: 100%;

    @include tablet {
      position: absolute;
      left: 50%; /* % of surrounding element */
      top: 50%;
      transform: translate(-50%, -50%); /* % of current element */
      max-width: none;
    }
  }

  &.is-fullheight {
    picture img {
      height: 100vh;
    }
  }

  &.is-fullheight-tablet {
    @include tablet {
      min-height: 100vh;
      height: 100vh;

      picture.object-fit-polyfill {
        height: 100%;
      }

      picture img {
        height: 100vh;
      }

      .is-landing-page & {
        min-height: 60vh;
        height: 60vh;
      }
    }

    &.elancer-palmer-videohero {
      &.is-always-fullscreen {
        min-height: 100vh;
        height: 100vh;
        margin-top: -58px;

        @media screen and (min-width: 768px) {
          min-height: auto;
          height: auto;
          margin-top: 0;
        }

        @media screen and (min-width: 1024px) {
          min-height: 100vh;
          height: 100vh;
          margin-top: 0;
        }


        video {
          position: absolute;
          top: 0;
          left: 0;
          max-width: none;
          width: auto;
          height: 100%;
          min-width: 100%;
          min-height: auto;
          transform: none;

          @media screen and (min-width: 768px) {
            height: 100%;
            width: 177.77777778vh; /* 100 * 16 / 9 */
            min-width: 100%;
            min-height: 56.25vw; /* 100 * 9 / 16 */
            position: static;
            transform: none;
            max-width: 100%;
          }

          @media screen and (min-width: 1024px) {
            height: 100%;
            width: 177.77777778vh; /* 100 * 16 / 9 */
            min-width: 100%;
            min-height: 56.25vw; /* 100 * 9 / 16 */
            position: absolute;
            left: 50%; /* % of surrounding element */
            top: 50%;
            transform: translate(-50%, -50%); /* % of current element */
            max-width: none;
          }
        }
      }
    }
  }

  .hero-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;

    @include desktop {
      background-attachment: fixed;

      .is-menu-open &,
      .is-overlay-open & {
        background-attachment: scroll;
      }
    }

    transition: transform 1.5s;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($palmer-blue, 0.4);
      opacity: 0;
      transition: opacity 1.5s;
    }

    &.first {
      background-color: $palmer-blue;

      svg {
        position: absolute;
        top: 50%;
        left: 35%;
        width: 260px;

        @include tablet {
          top: 50%;
          left: 65%;
          width: 300px;
        }

        @include desktop {
          top: 40%;
          left: 65%;
          width: 350px;
        }
      }
    }
  }

  .hero-cta {
    z-index: 2;
    position: absolute;
    bottom: retina(70px);
    left: retina(60px);
    right: retina(60px);

    @include tablet {
      left: retina(214px);
      right: retina(214px);
      bottom: retina(90px);
    }

    @include desktop {
      $container: (1440px - 2 * 145px);
      left: 50%;
      right: auto;
      margin-left: (-$container / 2);
    }

    @include neos-navigate-panel {
      left: 10px;
      right: 10px;
      margin-left: 0;
    }

    h1 {
      margin-bottom: 1rem !important;
      font-size: retina(44px);

      @include tablet {
        font-size: retina(58px);
        line-height: retina(58px);
      }

      @include desktop {
        font-size: 40px;
        line-height: 40px;
      }

      [lang="zhn"] & {
        margin-bottom: 1.7rem !important;
      }
    }

    h2 {
      font-size: retina(44px);

      @include tablet {
        font-size: retina(36px);
        line-height: retina(56px);
      }

      @include desktop {
        font-size: 20px;
        line-height: 30px;
      }
    }

    *:last-child {
      margin-bottom: 0;
    }
  }

  div.hero-cta {
    h1 {
      font-size: retina(88px);
      line-height: retina(92px);

      @include tablet {
        font-size: retina(58px);
        line-height: retina(58px);
      }

      @include desktop {
        font-size: 40px;
        line-height: 40px;
      }
    }
  }

  &.has-text {
    @include tablet {
      &:first-child {
        display: flex;
        background-color: $palmer-red;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;

        .hero-background.first {
          position: static;
          background-color: transparent;
          order: 1;

          svg {
            position: static;
          }
        }

        .hero-cta {
          position: static;
          margin-left: 0 !important;
          order: 0;
          margin-right: 100px;

          p {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    //@include desktop {
    //  &:first-child {
    //    .hero-cta {
    //      width: 710px;
    //    }
    //  }
    //}
  }

  &.has-text .hero-cta {
    bottom: auto;
    top: retina(120px);

    @include tablet {
      width: 50%;
      top: retina(350px);
    }

    @include desktop {
      top: 200px;
      $container: (1440px - 2 * 320px);
      width: ($container * 0.6);
      left: 50%;
      margin-left: -($container / 2);
    }

    h1 {
      @include tablet {
        font-size: retina(130px);
        line-height: retina(160px);
      }

      @include desktop {
        font-size: 72px;
        line-height: 72px;
      }
    }

    p {
      @include tablet {
        font-size: retina(36px);
        line-height: retina(46px);
      }
    }
  }

  a.read-more {
    position: absolute;
    bottom: 0;
    width: 100px;
    left: 50%;
    margin-left: -50px;
    margin-bottom: 0;

    svg path {
      stroke: $palmer-blue;
    }
  }
}

.hero + .hero {
  @extend .is-gapless-layout-element;
}

.hero.elancer-palmer-videohero a.read-more svg path {
  stroke: #fff;
}
