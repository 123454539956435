.is-bulk.is-404 {

  .content {

  }

  h1 {
    font-size: retina(58px);
    line-height: retina(68px);
    color: white;
    text-transform: uppercase;

    @include tablet {
      font-size: retina(103px);
      line-height: retina(103px);
    }
  }

  > main {
    padding-top: retina(226px);

    @include tablet {
      padding-left: retina(214px);
      padding-right: retina(214px);
    }

    @include desktop {
      padding: 166px 0;
      width: 1004px;
      margin: 0 auto;
    }
  }

  @include tablet {

    .content,
    aside {
      width: 55%;
    }

    .content {
      flex-grow: 1;
    }

    .content + img {

      position: absolute;
      top: 15vh;
      left: 55%;
      height: 70vh;
    }
  }

  @include desktop {
    .content,
    aside {
      width: 50%;
    }

    .content + img {
      left: 50%;
    }
  }
}