.pitch-container {
    background: #000;
    position: relative;
    overflow: hidden;

    .neos-contentcollection {
        display: block;
        height: 100%;
    }

    h2 {
        font-family: $univers-condensed-bold;
        color: $palmer-yellow;
    }

    h3 {
        color: #fff;
        text-transform: uppercase;
        font-family: $univers-condensed-light;
        font-size: 45px;
        line-height: 50px;
        margin-bottom: 15px;
    }

    #digitale-naehe h3 {
        color: $palmer-yellow;
    }

    p {
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        margin-bottom: 35px !important;
    }

    ul {
        li {
            font-size: 20px;
            line-height: 28px;

            position: relative;

            &::before {
                position: absolute;
                left: -14px;
                content: ' ';
                background: $palmer-red;
                height: 4px;
                width: 4px;
                top: 12px;
            }

        }
    }

    .typo3-neos-nodetypes-text,
    .pitch-header,
    .pitch-sub-header,
    .news-bubble {
        z-index: 100;
    }

    &.full-height {
        height: 100vh;
    }

    &.palmer-red {
        background-color: $palmer-red;

        h2, h3 {
            color: #fff;
        }
    }

    &.petrol {
        background-color: $palmer-blue;

        h2, h3 {
            color: #fff;
        }
    }

    &.palmer-yellow {
        background-color: $palmer-yellow;

        h2, h3 {
            color: $palmer-blue;
        }
    }

    &.palmer-grey {
        background-color: $palmer-grey;

        h2, h3 {
            color: $palmer-blue;
        }
    }

    .text-container {
        padding: 0;
    }

    .typo3-neos-nodetypes-text {
        li {
            &::before {
                color: $palmer-red;
            }
        }

        ul {
            font-size: 1rem;
            color: #fff;
            line-height: 22px;
            margin-top: 50px;
            margin-bottom: 25px;
        }
    }
}

.elancer-palmer-pitchheader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    h2, h3 {
        font-size: 45px;
        line-height: 50px;
        text-transform: uppercase;
    }
}

.elancer-palmer-absoluteimage {
    position: absolute !important;
    transform: translateY(0px);
}

.news-bubble {
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 40px;
    color: #fff;
    position: relative;

    span {
        font-size: 20px;
        line-height: 28px;
    }

    &.palmer-red {
        background-color: $palmer-red;

        &::before {
            content: ' ';
            position: absolute;
            height: 80px;
            width: 90px;
            top: -40px;
            left: calc(50% - 45px);
            background: url(../Images/red-triangle.svg) center center no-repeat;
        }
    }

    &.petrol {
        background-color: $palmer-blue;

        &::before {
            content: ' ';
            position: absolute;
            height: 80px;
            width: 90px;
            top: 20%;
            right: -40px;
            background: url(../Images/petrol-triangle.svg) center center no-repeat;
        }
    }

    &.palmer-yellow {
        background-color: $palmer-yellow;
        color: $palmer-blue;

        &::before {
            content: ' ';
            position: absolute;
            height: 80px;
            width: 90px;
            top: calc(50% - 40px);
            left: -60px;
            background: url(../Images/yellow-triangle.svg) center center no-repeat;
        }
    }

    &.palmer-grey {
        background-color: $palmer-grey;
        color: $palmer-blue;

        &::before {
            content: ' ';
            position: absolute;
            height: 80px;
            width: 90px;
            top: 20%;
            right: -60px;
            background: url(../Images/grey-triangle.svg) center center no-repeat;
        }
    }
}

// custom pitch styles

.a-to-button {
    p {
        a {
            border: 1px solid #fff;
            text-decoration: none;
            padding: 5px 20px;
            margin-top: 10px;
            display: inline-block;

            &:hover {
                color: #000;
                background: #fff;
                text-decoration: none;
            }
        }
    }
}

// Custom Styling Digitale Naehe

.yellow-h3 {
    h3 {
        color: $palmer-yellow !important;
        margin-bottom: 35px;
    }
}

.height-padding {
    padding-top: 170px;
    padding-bottom: 170px;
}

.pitch-in-page-menu {
    .text-container {
        p {
            display: flex;
            justify-content: space-between;

            a {
                text-decoration: none;
                font-family: $univers-condensed-bold;
                text-transform: uppercase;
                color: $palmer-red;

                &:nth-child(2) {
                    color: $palmer-blue;
                }

                &:nth-child(3) {
                    color: $palmer-yellow;
                }

                &:nth-child(4) {
                    color: $palmer-grey;
                }

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}

.pitch-container-padding-top > .neos-contentcollection {
    padding-top: 120px;
}

.pitch-container-padding-bottom {
    padding-bottom: 120px;
}

.pitch-container-padding-bottom-small {
    padding-bottom: 40px;
}

// Section Custom Styling

.first-pitch-container {
    height: 100%;

    & > .elancer-palmer-pitchtwocolumns {
        height: 100%;
    }

    video {
        width: 100%;
    }

    .secound-column {
        .neos-contentcollection {
            display: flex;
            align-items: flex-end;

            .text-container {
                padding-left: 10px;
                padding-bottom: 40px;
                max-width: 690px;
            }
        }
    }
}

.secound-pitch-container {
    display: flex;

    .first-column {
        h2 {
            color: $palmer-yellow;
        }
    }

    .secound-column {
        img,
        .elancer-palmer-video video {
            padding-left: 70px;
            padding-top: 60px;
        }
    }
}

.digital-zusammenarbeiten-text {
    padding-top: 450px;

    .secound-column {

        ul {
            margin-left: 60px;
        }
    }
}

.digital-is-networking,
.digitale-lead-generierung {
    ul {
        margin-top: 50px;
        margin-left: 55px;
        padding-right: 40px;
    }
}

.digital-is-networking {
    padding-top: 65px;
    padding-bottom: 15px;

    li {
        &::before {
            background-color: $palmer-pitch-li-color !important;
        }
    }

    .secound-column {
        .typo3-neos-nodetypes-image,
        .elancer-palmer-video video {
            padding-left: 90px;
            padding-top: 50px;
        }
    }
}

.digitale-lead-generierung {
    padding-top: 55px;
    padding-bottom: 13px;

    .first-column {
        img,
        .elancer-palmer-video video {
            padding: 50px 80px 0 80px;
        }
    }

    li {
        &::before {
            background-color: $palmer-yellow !important;
        }
    }
}

.c-level-kommunikation {
    padding-bottom: 13px;

    .first-column {
        .news-bubble {
            margin-top: 170px;
        }
    }

    .secound-column {
        img,
        .elancer-palmer-video video {
            padding-top: 42px;
            padding-left: 80px;
        }

        ul {
            margin-left: 70px;
            max-width: 480px;
        }
    }

    li {
        &::before {
            background-color: $palmer-pitch-li-color !important;
        }
    }
}

.taskforce {
    .first-column {
        padding: 100px;

        & > .neos-contentcollection {
            display: flex;
            align-items: center;
        }

        p {
            color: $palmer-blue;
            margin-bottom: 0 !important;
        }
    }
}

.no-overflow {
    overflow: unset !important;
}

.webinar-info-box {
    background: url(../Images/Webinar_squares.svg) center center no-repeat;

    margin-top: 80px;

    .elancer-palmer-pitchtwocolumns {
        border: 3px solid #fff;
        margin: 3px;

        p {
            font-size: 32px !important;
            line-height: 127.34% !important;
        }

        a {
            background-color: $palmer-turquoise !important;
            border-radius: 8px !important;
            color: #fff !important;
            padding: 10px 20px;
            text-decoration: none;
            font-size: 32px !important;
            font-family: $univers-condensed-bold;

            &:hover {
                background-color: #288188 !important;
                text-decoration: none;
            }
        }

        .first-column {
            padding: 120px 45px 45px 45px;
        }

        .secound-column {
            padding: 0;
            padding-right: 40px;
        }
    }
}

// mobile
@media (max-width: 768px) {
    .first-pitch-container-with-video {
        height: unset !important;
    }

    .first-pitch-container {
        .elancer-palmer-video {
            max-height: 340px;
            overflow: hidden;
        }

        .secound-column .neos-contentcollection .text-container {
            padding: 0 25px 0;

            p a {
                width: 100%;
                text-align: center;
                padding: 13px;
            }
        }
    }

    .pitch-container {
        .container {
            padding: 0 25px;
        }

        .pitch-header,
        .pitch-sub-header {
            text-align: center;
            padding: 0 25px;
            margin-bottom: 0;
        }

        h2, h3 {
            font-size: 27px;
            line-height: 34px;
        }

        .yellow-h3 h3 {
            margin-bottom: 34px;
        }

        .elancer-palmer-pitchheader {
            h2, h3 {
                font-size: 27px;
                line-height: 34px;
            }
        }

        p,
        ul li,
        .news-bubble span {
            font-size: 17px;
            line-height: 22px;
        }
    }

    .elancer-palmer-absoluteimage {
        display: none;

        &.show-on-mobile {
            display: block;
        }

        img {
            transform-origin: top left;
            transform: scale(0.6);
        }
    }

    .pitch-in-page-menu .text-container p {
        flex-direction: row;

        a {
            flex: 50%;
        }

        &:first-child {
            padding-bottom: 30px;
        }

        &:last-child {
            a {
                &:first-child {
                    color: $palmer-yellow;
                }

                &:last-child {
                    color: $palmer-grey;
                }
            }
        }
    }

    .digital-zusammenarbeiten-text {
        padding-top: 0;

        .elancer-palmer-pitchtwocolumns {

            &:first-child {
                display: block;

                ul {
                    margin-top: 0;
                    margin-bottom: 0px !important;
                }
            }
        }

        .webinar-info-box {
            .elancer-palmer-pitchtwocolumns {
                display: flex;
                flex-direction: column;

                .first-column {
                    order: 2;
                }

                .secound-column {
                    order: 1;
                }
            }
        }
    }

    .interne-kommunikation {
        img {
            padding: 50px 10px;
        }
    }

    .taskforce .first-column {
        padding: 70px 50px 0;
    }

    .pitch-image-container .neos-contentcollection .pitch-person {
        width: 33.33%;

        .info {
            .name {
                font-size: 15px !important;
            }

            .department,
            .info-text {
                font-size: 10px !important;
                line-height: 14px !important;
            }

            .department {
                margin-bottom: 7px !important;
                text-transform: none !important;
            }
        }
    }

    .mobile-padding {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .pitch-container-padding-top {
        padding-top: 40px;
    }

    .digital-zusammenarbeiten-text .secound-column ul,
    .c-level-kommunikation .secound-column ul {
        margin-left: 40px;
    }

    .c-level-kommunikation {
        .first-column {
            img,
            .elancer-palmer-video video {
                margin-top: 50px;
                padding: 0 10px;
            }
        }

        .secound-column ul {
            margin-top: 0;
        }
    }

    .digitale-lead-generierung .first-column {
        img,
        .elancer-palmer-video video {
            padding: 40px 20px 0;
        }
    }

    .webinar-info-box {
        margin-top: 40px;
        margin-bottom: 20px;
        background-size: cover;

        .elancer-palmer-pitchtwocolumns {
            margin: 0 -20px;

            p, a {
                font-size: 21px !important;
                margin-bottom: 13px !important;
            }

            a {
                margin-top: 20px;
                width: 100% !important;
                display: block !important;
                text-align: center !important;
                margin-bottom: -33px !important;
                padding: 20px 10px !important;
            }

            img {
                max-height: 350px;
            }

            .first-column {
                padding: 0 15px;
            }

            .secound-column {
                padding: 0;
            }
        }
    }

    .secound-pitch-container > .elancer-palmer-pitchtwocolumns {
        display: flex;
        flex-direction: column;

        .first-column {
            order: 2;
        }

        .secound-column {
            order: 1;

            .typo3-neos-nodetypes-image,
            .elancer-palmer-video video {
                margin-bottom: 0 !important;
                margin-top: -30px !important;
            }
        }
    }

    // paddings

    .secound-pitch-container-2 {
        padding-top: 0 !important;
    }

    .pitch-in-page-menu {
        p {
            margin-bottom: 0 !important;
        }
    }

    .pitch-container-padding-bottom,
    .pitch-container-padding-bottom-small {
        padding-bottom: 50px;
    }

    .pitch-container-padding-top>.neos-contentcollection {
        padding-top: 50px;
    }

    .secound-pitch-container .secound-column {
        img,
        .elancer-palmer-video video {
            padding: 10px 0 0 0;
        }
    }

    .digitale-lead-generierung {
        padding-top: 0 !important;

        &> .neos-contentcollection {
            padding-top: 10px;

            .is-full-image-container,
            .elancer-palmer-video video {
                margin-bottom: 0 !important;
            }
        }
    }

    .pitch-container ul li::before {
        top: 9px;
    }

    .pitch-container {
        .digitale-lead-generierung .typo3-neos-nodetypes-text,
        .digital-is-networking .typo3-neos-nodetypes-text {
            ul {
                margin-bottom: 0 !important;
            }
        }
    }

    .c-level-kommunikation .first-column .news-bubble {
        margin-top: 50px;
    }

    .pitch-container p.taskforce-info {
        margin-bottom: 15px !important;
    }

    .kostenlose-beratung {
        .text-container {
            z-index: 100;
            position: relative;
        }
    }
}


// Video Hero on start page

.simple-button {
    border: 1px solid #fff;
    padding: 5px 20px;
    margin-top: 20px;

    &:hover {
        text-decoration: none;
        color: #000;
        background: #fff;
    }
}

.digitale-naehe-hero {
    margin-left: -1px !important; // fix 1px white vertical line on some devices

    .video-overlay {
        h1 {
            color: $palmer-yellow;
        }

        h1,
        h2 {
            font-size: 27px;
            line-height: 34px;
        }

        a {
            font-size: 17px;
            line-height: 22px;
        }
    }
}

@media screen and (min-width: 769px) {
    .digitale-naehe-hero {
        .video-overlay {
            h1,
            h2 {
                font-size: 27px;
                line-height: 34px;
            }

            a {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .digitale-naehe-hero {
        .video-overlay {
            h1,
            h2 {
                font-size: 50px;
                line-height: 65px;
            }

            a {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}

.ceo-linkedindex-hero {
    margin-left: -1px !important; // fix 1px white vertical line on some devices

    .video-overlay {
        h1, h2 {
            color: $palmer-yellow;
        }

        h1 {
            font-size: 33px;
            line-height: 40px;
            font-weight: 700;
        }

        h2 {
            font-size: 29px;
            line-height: 40px;
            font-weight: 300;
        }

        a {
            font-size: 20px;
            line-height: 28px;
            margin-top: 30px;
            font-family: "Univers LT 67";
            font-weight: 700;
            color: $palmer-blue;
            background: $palmer-yellow;
            border: 1px solid $palmer-yellow;

            &:hover {
                color: $palmer-yellow;
                background: transparent;
            }
        }
    }
}

@media screen and (min-width: 769px) {
    .ceo-linkedindex-hero {
        .video-overlay {
            h1 {
                font-size: 50px;
                line-height: 45px;
            }
            h2 {
                font-size: 27px;
                line-height: 34px;
            }
            a {
                margin-top: 30px;
                min-width: auto;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .ceo-linkedindex-hero {
        .video-overlay {
            h1 {
                font-size: 110px;
                line-height: 100px;
            }
            h2 {
                font-size: 49px;
                line-height: 60px;
            }
            a {
                min-width: 353px;
                font-size: 32px;
                margin-top: 100px;
                line-height: 40px;
            }
        }
    }
}



@media (min-width: 1270px ) and (max-width: 1390px) {
    .taskforce .first-column {
        padding: 70px;
    }
}

@media (min-width: 769px) and (max-width: 1269px) {
    .taskforce {
        .columns {
            display: block !important;

            .column {
                width: 100% !important;
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1199px) {
    .pitch-container {
        .text-container {
            max-width: none;
        }
    }
}

@media (max-width: 1630px) {
    .elancer-palmer-absoluteimage {
        img {
            transform: scale(0.6);
        }
    }
}

//iPad Pro

@media (min-width: 769px) and (max-width: 1024px) {
    .first-pitch-container-with-video {
        &.full-height {
            height: auto !important;
        }

        .elancer-palmer-pitchtwocolumns {
            display: block;

            .first-column,
            .secound-column {
                width: 100%;
            }

            .secound-column {
                margin-top: 130px;
            }
        }
    }

    .first-pitch-container {
        .elancer-palmer-video {
            max-height: 600px;
            overflow: hidden;
        }

        .text-container {
            max-width: none;
            padding: 0 75px 0 0;
        }

        .secound-column .neos-contentcollection .text-container {
            padding: 0 25px 0;

            p a {
                width: 100%;
                text-align: center;
                padding: 13px;
            }
        }
    }

    .interne-kommunikation {
        ul  {
            margin-right: 50px;
        }
    }

    .c-level-kommunikation {
        .first-column {
            margin-left: 0;
        }

        ul {
            margin-right: 25px;
        }
    }

    .taskforce .columns .column .is-4 {
        width: 33.33333% !important;
    }
}
