.team {
  overflow: hidden;
  color: white;

  .load-more {
    transition: all 0.5s;

    &.is-loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .search {
    border: 1px solid white;
    margin-bottom: (24px / 2);
    height: (54px / 2);
    position: relative;

    &:after {
      content: '';
      background: url('../Images/search.svg') center center no-repeat;
      background-size: 100%;
      width: 15px;
      position: absolute;
      top: 0;
      right: 3px;
      bottom: 0;
    }


    input {
      color: white;
      background: transparent;
      border: none;
      display: block;
      width: 100%;
      font-size: (20px / 2);
      padding: 7px 25px 0 10px;
      -webkit-appearance: none;

      @include placeholder {
        color: white;
      }
    }


    @include desktop {
      width: 415px;
      height: 34px;
      margin-bottom: 75px;

      input {
        font-size: 16px;
      }
    }
  }

  ul.filter {
    font-family: $univers-condensed-bold;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    //justify-content: space-between;
    $spaceBetween: 84px;
    $padding: 10px;

    margin: (-$padding) (-$padding) (66px - $padding);

    li {
      a,
      span {
        text-transform: uppercase;
        padding: $padding;
      }
    }
  }

  div.filter {
    select {
      color: white;
    }

    &:after {
      background-image: url(../Images/arrow-right.svg);
    }
  }

  a {
    color: white;
  }

  figure {
    img {
      display: block;
      margin-bottom: (10px /2);
      border-right: 1px solid $palmer-red;
    }
    figcaption {
      font-size: (24px / 2);
      line-height: (24px / 2);
    }
  }

  .team-list {
    margin-right: -1px;

    &.is-animatable > .column {
      transition: all 0.5s;
    }
  }

  .team-list > .column {
    flex: none;
    width: (100% / 2);
    margin-bottom: (20px / 2);
    overflow: hidden;


    @include tablet {
      width: (100% / 4);
    }

    @include desktop {
      width: (100% / 7);
    }

    &.is-invisible,
    &.is-filtered-out {
      width: 0;
      opacity: 0;
    }

    img {
      object-fit: cover;

      //height: 100%;
      width: 100%;

      /*
      @include tablet {
        height: 201px;
        width: 201px;
      }

      @include desktop {
        height: 160px;
        width: 160px;
      }
      */
    }

    & + .detail {
      display: none;
      pointer-events: none;
      overflow: visible;
      border-right: 2px solid $palmer-red;

      $sizeDuration: 0.5s;
      $fadeDuration: 0.3s;

      button.close-button {
        position: absolute;
        top: 9px;
        right: 8px;
        background: transparent;
        border: none;
        width: retina(60px);
        height: retina(60px);
        cursor: pointer;
        margin: 0;
        padding: 0;

        @include tablet {
          top: 14px;
          right: 14px;
        }

        span {
          display: block;
          height: 2px;
          width: 100%;
          background: $palmer-light-blue;
          transform: rotate(45deg);
          position: absolute;
          border-radius: 2px;
          top: 50%;
          left: 0;

          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }

      transition:
        opacity $fadeDuration ease-in-out,
        height $sizeDuration ease-in-out $fadeDuration,
        margin $sizeDuration ease-in-out $fadeDuration,
        padding $sizeDuration ease-in-out $fadeDuration;

      &.is-added {
        display: block;
        opacity: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &.is-visible {
        pointer-events: all;
        transition:
          height $sizeDuration ease-in-out,
          margin $sizeDuration ease-in-out,
          padding $sizeDuration ease-in-out,
          opacity $fadeDuration ease-in-out $sizeDuration;

        height: auto;
        opacity: 1;
        padding: (24px / 2);
        margin-bottom: (20px / 2);

        @include tablet {
          padding: (30px / 2);
        }

        &.is-delayed {
          transition:
            height 0s ease-in-out $fadeDuration,
            margin 0s ease-in-out $fadeDuration,
            padding 0s ease-in-out $fadeDuration,
            opacity $fadeDuration ease-in-out $fadeDuration;
        }
      }

      &.is-delayed {
        transition:
          height 0s ease-in-out $fadeDuration,
          margin 0s ease-in-out $fadeDuration,
          padding 0s ease-in-out $fadeDuration,
          opacity $fadeDuration ease-in-out;
      }
    }

    &.detail {
      width: 100%;
      background: white;
      color: $palmer-blue;
      font-size: (30px / 2);
      line-height: (40px / 2);
      position: relative;

      .arrow {
        width: 0;
        height: 0;
        border-style: solid;
        $size: 8px;
        border-width: 0 $size $size $size;
        position: absolute;
        top: -$size;
        left: 0;
        border-color: transparent transparent white transparent;
        margin-left: -$size;
      }

      @mixin position($elementsPerRow) {
        @for $i from 1 through $elementsPerRow {
          &.position-#{$i} .arrow {
            left: ((100% / $elementsPerRow) * (0.5 + ($i - 1)));
          }
        }
      }

      @include position(2);
      @include tablet { @include position(4) }
      @include desktop { @include position(7) }

      img {
        display: block;
      }

      @include clearfix;
      .grid {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        &:after {
          content: '';
          display: table;
          clear: both;
        }

        &.imageOnly {
          flex-direction: row;
          flex-wrap: wrap;

          .is-image {
            width: 100%;
            max-width: 100%;
            float: none;
            flex: inherit;

            @include tablet {
              margin-right: 0;
            }

            img,
            video {

              @include tablet {
                width: 779px;
                height: (770px / (16 / 9));
              }

              @include desktop {
                width: 1109px;
                height: (1109px / (16 / 9));
              }
            }
          }

          .is-title {
            order: -2;

            h1 {
              float: left;
            }

            .social {
              float: left;
            }

            h2 {
              clear: both;
              display: block;
            }

          }

          .is-text {
            width: 100%;

            @include tablet {
              width: auto;
              order: -1;
              padding-top: 0;
            }
          }

          .social {
            @include tablet {
              position: static;
            }
          }
        }

        .is-image {
          flex: none;
          overflow: hidden;

          height: 0;
          padding-bottom: ((9 / 16) * 100) * 1%;

          @include tablet {
            height: auto;
            padding-bottom: 0;
          }

          img,
          video {
            width: 100%;
            max-width: 100%;
            display: block;
            object-fit: cover;

            @include tablet {
              width: 390px;
              height: (390px / (16 / 9));
            }

            @include desktop {
              width: 555px;
              height: (555px / (16 / 9));
            }
          }


        }

        .is-text {
          padding-top: 20px;
        }

        .is-title {
          order: -1;
          margin-bottom: 20px;
          h1 {
            padding-right: 30px;
          }
        }

        @include tablet {
          display: block;

          &.imageOnly {
            display: flex;
          }

          .is-image {
            float: left;
            margin-right: 30px;
            margin-bottom: 0;
          }

          .is-text {
            padding-right: 30px;

            p {
              font-size: retina(34px);
              line-height: retina(44px);
              width: auto;
            }
          }
        }
      }



      h1, h2 {
        font-size: (32px / 2);
        line-height: (32px / 2);

        @include tablet {
          font-size: (58px / 2);
          line-height: (58px / 2);
        }
      }

      h1 {
        margin-bottom: 0;
      }

      h2 {
        margin-top: -2px;
        margin-bottom: 0;
        padding-top: 5px;
        display: inline-block;
        position: relative;
        top: 6px;

        &:before {
          content: '';
          width: 40px;
          height: 3px;
          background: $palmer-blue;
          position: absolute;
          top: -1px;
        }
      }

      p {
        @include layout-element(0.5);
        font-size: (30px / 2);
        line-height: (40px / 2);

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .social {
        height: 32px;
        margin-bottom: 0 !important;

        @include tablet {

          position: absolute;
          right: (30px / 2);
          bottom: (30px / 2);
        }
      }
    }
  }
}
