.blog-article {

  .full-text-container {

    &.elancer-palmer-video {
      padding-left: 0;
      padding-right: 0;

      iframe {
        width: 100%;
      }

      video {
        width: 100%;
      }
    }
  }
}
