.two-videos-element {
    .left-video {
    }

    .right-video {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .left-video,
    .right-video {
        video {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .two-videos-element {
        margin: 0 auto 150px !important;

        .left-video {
            padding-right: 80px;
            justify-content: flex-end;
        }

        .right-video {
            padding-top: 160px;
            padding-left: 80px;
            padding-bottom: 0;
        }

        .left-video,
        .right-video {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            video {
                max-width: 375px;
            }
        }
    }
}


@media screen and (min-width: 1200px) {
    .two-videos-element {
        max-width: 1140px;
        padding: 0;
        //margin: 0 auto 150px !important;
    }
}
