@for $i from 1 through 5 {
  .space-#{$i * 10} {
    padding-top: ($i * 10) + vh;
  }
}

@include tablet {
  @for $i from 1 through 5 {
    .space-#{$i * 10}-tablet {
      padding-top: ($i * 10) + vh;
    }
  }
}

@include desktop {
  @for $i from 1 through 5 {
    .space-#{$i * 10}-desktop {
      padding-top: ($i * 10) + vh;
    }
  }
}