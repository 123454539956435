@import "neos.mixins";

@import "mixins";
@import "variables";
@import "bulma-setup";
@import "bulk";
@import "404";
@import "layout";
@import "typography";
@import "icons";

@import "overlay";
@import "button";
@import "section";
@import "article";
@import "menu";
@import "spacer";
@import "hero";
@import "teaser";
@import "footer";
@import "image-grid";
@import "more";
@import "team";
@import "projects";
@import "filter";
@import "closeup";
@import "form";
@import "bubble-info";
@import "blog";
@import "slider";
@import "neos";
@import "video";
@import "cannes";
@import "cookie-notice";
@import "pitch-page";
@import "pitch-image-container";
@import "ceo-linkedin-button";
@import "backgroundcontainer";
@import "two-videos-element";
@import "onemagnify";
@import "herophonemagnify";

* {
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  > main {
    flex-grow: 1;
    overflow: hidden;

    margin-top: 58px;
    @include tablet {
      margin-top: 0;
    }

    > .neos-contentcollection {
      > .typo3-neos-nodetypes-text:last-of-type {
        @include layout-element(2);
      }

      > .typo3-neos-nodetypes-text + .elancer-palmer-sectionheader {
        margin-top: 154px!important;
      }
    }
  }
}

.margin-b-35-77 {
    margin-bottom: 35px;
}

@media screen and (min-width: 769px) {
    .margin-b-35-77 {
        margin-bottom: 77px;
    }
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

figcaption {
  margin-bottom: 20px;

  p {
    margin-bottom: 0 !important;
  }
}

.has-logos {
  @include layout-element(0.5);
  div.columns {
    justify-content: space-around;
    flex-wrap: wrap;

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      width: (100% / 3);
    }

    @include tablet {
      > * {
        width: (100% / 6);
      }
    }
  }

  img {
    width: 190px;
  }

  + .elancer-palmer-sectionheader {
    > .section-header {
      margin-top: 0 !important;
    }
  }
}

.elancer-palmer-video .youtube-player {
  position: relative;
  padding-bottom: ((9 / 16) * 100) * 1%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

iframe {
  max-width: 100%;
}
