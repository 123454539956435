footer {
  @extend .is-gapless-layout-element;

  background: $palmer-blue;
  color: white;

  padding: (40px / 2) (65px / 2);

  a:hover {
    font-weight: bold;
  }

  > div {

    > ul > li {
      margin-bottom: $spaceBetweenElements !important;

      &:first-child {
        //margin-bottom: 20px !important;
      }
    }

    @include tablet {
      display: flex;

      > ul {
        position: relative;
        justify-content: center;
        flex-grow: 1;

        > li {
          margin-bottom: 0 !important;
        }

        li:last-child {
          flex-grow: 0;
        }
      }
    }
  }

  @include tablet {
    padding-top: (70px / 2);
    .columns {
      margin-bottom: 0;
    }
  }

  @include desktop {
    padding-top: 50px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  > ul {
    max-width: 1280px;
    margin-left: auto !important;
    margin-right: auto !important;

    > li {
      flex-grow: 0 !important;
    }
  }

  h2 {
    white-space: nowrap;
    font-size: (32px / 2);
    font-family: $univers-condensed-bold;
    margin-bottom: 0;

    @include tablet {
      font-size: retina(36px);
      line-height: retina(38px);
      margin-bottom: retina(60px);
    }

    @include desktop {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 42px;
    }
  }

  .is-bordered {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin: -17px 0 17px !important;
    padding: 10px 0 !important;

    @include tablet {
      border: none;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  > .columns {
    position: relative;
  }

  > .columns > .column {
    margin-bottom: (30px / 2) !important;
    padding-bottom: (30px / 2) !important;
    border-bottom: 1px solid white;

    &:last-child {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      border-bottom: none;
    }

    @include tablet {
      border: none;
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  p {
    font-size: (28px / 2);
    line-height: (38px / 2);
    margin-bottom: (38px / 2);

    color: white;

    a {
      color: white;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    @include tablet {
      font-size: (26px / 2);
      line-height: (34px / 2);

      &.imprint {

        a {
          white-space: nowrap;;
        }
      }
    }

    @include desktop {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .link-list {
    > div,
    a {
      font-size: (28px / 2);
      line-height: (38px / 2);
      display: block;

      @include tablet {
        font-size: (26px / 2);
        line-height: (34px / 2);
      }

      @include desktop {
        font-size: 18px;
        line-height: 24px;
      }

      &:after {
        content: '';
        display: block;
      }
    }
  }

  .press {

    li {
      margin-bottom: (32px / 2);

      &:last-child {
        margin-bottom: 0;
      }

      @include tablet {
        margin: 0;
      }
    }

    .social {
      div[class*=elancer-palmer] {
        display: inline-block;
      }

      a {
        span {
          display: inline-block !important;
        }
      }
    }

    a {
      color: white;

      display: block;

      font-family: $univers-light;

      border: none;
      height: auto;
      padding: 0;
      text-transform: none;
      margin-bottom: 0;
      font-size: (28px / 2);
      line-height: (38px / 2);

      span {
        display: none !important;
      }


      &:last-child {
        margin-bottom: 0;
      }


      @include desktop {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .social,
  .social .neos-contentcollection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -2px;
    min-width: 50px;

    a {
      &:hover {
        font-weight: normal;
      }
    }

    > * {
      margin: 0 2px;
    }

    @include tablet {
      width: 144px;
      justify-content: flex-start;

      .column {
        margin-right: 5px !important;
      }
    }
  }

  .elancer-palmer-newbusinessmember {
    margin: 1em 0 0 0;

    @include tablet {
      margin: 0 0 4em 0;
    }

    @include desktop {
      margin: 0 0 4em 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .contact-person {

    @include tablet {
      position: relative;
      height: 150px;
      padding-left: 190px;
    }

    @include desktop {
      position: relative;
      height: 200px;
      padding-left: 240px;
    }

    p {
      margin-bottom: 0.5em !important;
    }

    img {
      @include tablet {
        position: absolute;
        top:0;
        left: 0;
        width: 150px;
        height: auto;
      }

      @include desktop {
        width: 200px;
        height: auto;
      }
    }

    a.download-link {
      position: relative;
      padding-left: .7em;
      display: block;
      margin-top: -3px;


      &:before {
        content: '';
        height: 10px;
        background: url(../Images/arrow-right.svg) left center no-repeat;
        width: 10px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -5px;
      }
    }

    .social {
      padding-top: 3px;
      justify-content: flex-start;

      @include tablet {
        padding-top: 0;
      }
    }
  }

  .contact {
    @include tablet {
      height: 127px;
      margin-bottom: 4em;
    }

    @include desktop {
      height: 177px;
    }
  }

  .container {
    &:first-child {
      margin-bottom: $spaceBetweenElements;

      @include tablet {
        margin-bottom: $spaceBetweenElements-tablet;
      }

      @include desktop {
        margin-bottom: $spaceBetweenElements-desktop;
      }
    }

    &:last-child {
      .columns {
        flex: 1;
        align-items: flex-end;
      }
    }
  }

  .certificates {
    margin-top: 1em;

    @include tablet {
      margin-top: 0;
    }

    > div {
      display: flex;
      justify-content: space-between;

      @include tablet {
        justify-content: flex-start;

      }

      figure {
        @include tablet {
          margin-left: 0.5em;
        }
      }
    }
  }
}
