.read-more,
.load-more {

  svg {
    width: 50px;
    height: 5px;
    margin: 0 auto;

    path {
      fill: none;
      stroke: white;
      stroke-width: 2px;
      stroke-linecap: round;

      section.turquoise & {
        stroke: $palmer-blue;
      }
    }

    @include tablet {
      width: 100px;
      height: 10px;

      path {
        stroke-width: 1px;
      }
    }
  }
}

.read-more {

  $bounceHeight: 15px;
  @keyframes bounce {
    0% {
      transform: translate(0px,0px)
    }
    4% {
      transform: translate(0px,-$bounceHeight)
    }
    8% {
      transform: translate(0px,0px)
    }
    11% {
      transform: translate(0px,(-$bounceHeight * 0.6))
    }
    15% {
      transform: translate(0px,0px)
    }
    19% {
      transform: translate(0px,(-$bounceHeight * 0.2))
    }
    25% {
      transform: translate(0px,0px)
    }
    100% {
      transform: translate(0px,0px)
    }
  }

  display: block;
  margin-bottom: (76px / 2 - 20px);
  padding: 20px 0;
  text-align: center;
  font-size: 0;
  line-height: 0;
  z-index: 2;
  animation: bounce 4s infinite ease-in-out;

  @include tablet {
    margin-bottom: (66px / 2 - 20px);
  }

  &.is-blue {
    svg path {
      stroke: $palmer-blue;
    }
  }
}

.load-more {
  display: block;
  font-size: (28px / 2);
  font-family: $univers-light;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;

  span {
    display: block;
  }

  svg {
    width: 40px;
    height: 10px;
  }
}


#eapps-instagram-feed-1 {
  .eapps-instagram-feed-posts-grid-load-more {
    background-color: transparent !important;
    border-radius: 0 !important;

    .eapps-instagram-feed-posts-grid-load-more-text {
     @extend .load-more;
      background-color: transparent !important;
      color: $palmer-red !important;
      position: relative;

      &:active, &:focus, &:hover {
        background-color: transparent !important;
        color: $palmer-red !important;
      }

      &:after {
        content: "";
        display: block;
        width: 40px;
        height: 10px;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(../Images/more-arrow.svg);
        background-size: cover;
        background-position: center center;
      }
    }
  }
}
