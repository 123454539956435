.blog-article .elancer-palmer-slider {
  padding-left: 0;
  padding-right: 0;
}

.elancer-palmer-slider {
  .neos-empty-contentcollection-overlay {
    min-height: 50px;
    margin-bottom: 20px;
    outline-offset: -2px !important;
  }

  .neos-contentcollection {
    min-width: 100%;
  }
}

.lslide {
  display: flex;
  flex: none;
  align-items: stretch;

  figure {
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}
.lSSlideOuter {
  overflow: hidden;
  user-select: none;
}
.lightSlider:before, .lightSlider:after {
  content: " ";
  display: table;
}
.lightSlider {
  overflow: hidden;
  margin: 0;
  display: flex;
  height: 56.25vw;

  @include tablet {
    height: 500px;
  }
}

.lSSlideWrapper {
  max-width: 100%;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';

  }
}
.lSSlideWrapper > .lightSlider:after {
  clear: both;
}
.lSSlideWrapper .lSSlide {
  transform: translate(0px, 0px);
  -webkit-transition: all 1s;
  -webkit-transition-property: -webkit-transform,height;
  -moz-transition-property: -moz-transform,height;
  transition-property: transform,height;
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lSSlideWrapper .lSFade {
  position: relative;
}
.lSSlideWrapper .lSFade > * {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 9;
  margin-right: 0;
  width: 100%;
}
.lSSlideWrapper.usingCss .lSFade > * {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important;
}
.lSSlideWrapper .lSFade > *.active {
  z-index: 10;
}
.lSSlideWrapper.usingCss .lSFade > *.active {
  opacity: 1;
}
/** /!!! End of core css Should not edit !!!/**/

/* Pager */
.lSSlideOuter .lSPager.lSpg {
  margin: 10px 0 0;
  padding: 0;
  text-align: center;
}
.lSSlideOuter .lSPager.lSpg > li {
  cursor: pointer;
  display: inline-block;
  padding: 0 5px;

  @include tablet {
    padding: 0 5px;
  }
}
.lSSlideOuter .lSPager.lSpg > li a {

  border-radius: 30px;
  display: inline-block;
  height: 10px;
  width: 10px;
  border: 1px solid $palmer-red;
  overflow: hidden;
  text-indent: -999em;
  position: relative;
  z-index: 99;
  transition: all 0.5s linear 0s;

  @include tablet {
    height: 13px;
    width: 13px;
  }
}
.lSSlideOuter .lSPager.lSpg > li:hover a, .lSSlideOuter .lSPager.lSpg > li.active a {
  background-color: $palmer-red;
}
.lSSlideOuter .media {
  opacity: 0.8;
}
.lSSlideOuter .media.active {
  opacity: 1;
}
/* End of pager */

/** Gallery */
.lSSlideOuter .lSPager.lSGallery {
  list-style: none outside none;
  padding-left: 0;
  margin: 0;
  overflow: hidden;
  transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate3d(0px, 0px, 0px);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  user-select: none;
}
.lSSlideOuter .lSPager.lSGallery li {
  overflow: hidden;
  -webkit-transition: border-radius 0.12s linear 0s 0.35s linear 0s;
  transition: border-radius 0.12s linear 0s 0.35s linear 0s;
}
.lSSlideOuter .lSPager.lSGallery li.active, .lSSlideOuter .lSPager.lSGallery li:hover {
  border-radius: 5px;
}
.lSSlideOuter .lSPager.lSGallery img {
  display: block;
  height: auto;
  max-width: 100%;
}
.lSSlideOuter .lSPager.lSGallery:before, .lSSlideOuter .lSPager.lSGallery:after {
  content: " ";
  display: table;
}
.lSSlideOuter .lSPager.lSGallery:after {
  clear: both;
}
/* End of Gallery*/

/* slider actions */
.lSAction > a {
  width: 32px;
  display: block;
  top: 50%;
  height: 32px;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  margin-top: -16px;
  -webkit-transition: opacity 0.35s linear 0s;
  transition: opacity 0.35s linear 0s;

  span {
    height: 2px;
    width: 25px;
    display: block;
    background: white;
    border-radius: 2px;
    transform: translateY(15px) rotate(45deg);
    transform-origin: 0 50%;

    &:last-child {
      transform: translateY(13px) rotate(-45deg);
    }
  }
}
.lSAction > a:hover {
  opacity: 1;
}
.lSAction > .lSPrev {
  background-position: 0 0;
  left: 10px;
}
.lSAction > .lSNext {
  background-position: -32px 0;
  right: 10px;
  transform: rotate(180deg);
}
.lSAction > a.disabled {
  pointer-events: none;
}
.cS-hidden {
  height: 1px;
  opacity: 0;
  filter: alpha(opacity=0);
  overflow: hidden;
}


/* vertical */
.lSSlideOuter.vertical {
  position: relative;
}
.lSSlideOuter.vertical.noPager {
  padding-right: 0px !important;
}
.lSSlideOuter.vertical .lSGallery {
  position: absolute !important;
  right: 0;
  top: 0;
}
.lSSlideOuter.vertical .lightSlider > * {
  width: 100% !important;
  max-width: none !important;
}

/* vertical controlls */
.lSSlideOuter.vertical .lSAction > a {
  left: 50%;
  margin-left: -14px;
  margin-top: 0;
}
.lSSlideOuter.vertical .lSAction > .lSNext {
  background-position: 31px -31px;
  bottom: 10px;
  top: auto;
}
.lSSlideOuter.vertical .lSAction > .lSPrev {
  background-position: 0 -31px;
  bottom: auto;
  top: 10px;
}
/* vertical */


/* Rtl */
.lSSlideOuter.lSrtl {
  direction: rtl;
}
.lSSlideOuter .lightSlider, .lSSlideOuter .lSPager {
  padding-left: 0;
  list-style: none outside none;
}
.lSSlideOuter.lSrtl .lightSlider, .lSSlideOuter.lSrtl .lSPager {
  padding-right: 0;
}
.lSSlideOuter .lightSlider > *,  .lSSlideOuter .lSGallery li {
  float: left;
}
.lSSlideOuter.lSrtl .lightSlider > *,  .lSSlideOuter.lSrtl .lSGallery li {
  float: right !important;
}
/* Rtl */

@-webkit-keyframes rightEnd {
  0% {
    left: 0;
  }

  50% {
    left: -15px;
  }

  100% {
    left: 0;
  }
}
@keyframes rightEnd {
  0% {
    left: 0;
  }

  50% {
    left: -15px;
  }

  100% {
    left: 0;
  }
}
@-webkit-keyframes topEnd {
  0% {
    top: 0;
  }

  50% {
    top: -15px;
  }

  100% {
    top: 0;
  }
}
@keyframes topEnd {
  0% {
    top: 0;
  }

  50% {
    top: -15px;
  }

  100% {
    top: 0;
  }
}
@-webkit-keyframes leftEnd {
  0% {
    left: 0;
  }

  50% {
    left: 15px;
  }

  100% {
    left: 0;
  }
}
@keyframes leftEnd {
  0% {
    left: 0;
  }

  50% {
    left: 15px;
  }

  100% {
    left: 0;
  }
}
@-webkit-keyframes bottomEnd {
  0% {
    bottom: 0;
  }

  50% {
    bottom: -15px;
  }

  100% {
    bottom: 0;
  }
}
@keyframes bottomEnd {
  0% {
    bottom: 0;
  }

  50% {
    bottom: -15px;
  }

  100% {
    bottom: 0;
  }
}
.lSSlideOuter .rightEnd {
  -webkit-animation: rightEnd 0.3s;
  animation: rightEnd 0.3s;
  position: relative;
}
.lSSlideOuter .leftEnd {
  -webkit-animation: leftEnd 0.3s;
  animation: leftEnd 0.3s;
  position: relative;
}
.lSSlideOuter.vertical .rightEnd {
  -webkit-animation: topEnd 0.3s;
  animation: topEnd 0.3s;
  position: relative;
}
.lSSlideOuter.vertical .leftEnd {
  -webkit-animation: bottomEnd 0.3s;
  animation: bottomEnd 0.3s;
  position: relative;
}
.lSSlideOuter.lSrtl .rightEnd {
  -webkit-animation: leftEnd 0.3s;
  animation: leftEnd 0.3s;
  position: relative;
}
.lSSlideOuter.lSrtl .leftEnd {
  -webkit-animation: rightEnd 0.3s;
  animation: rightEnd 0.3s;
  position: relative;
}
/*/  GRab cursor */
.lightSlider.lsGrab > * {
  cursor: grab;
}
.lightSlider.lsGrabbing > * {
  cursor: move;
  cursor: grabbing;
}


.lg-actions {
  .lg-next,
  .lg-prev {
    height: 40px;
    width: 40px;
    margin-top: -20px;
    background-color: transparent;
    border: none;

    &:before,
    &:after {
      content: '';
      width: 25px;
      height: 2px;
      border-radius: 2px;
      background: white;
      display: block;
      transform-origin: 0 50%;
    }

    &:before {
      transform: translateY(0px) rotate(45deg);
    }

    &:after {
      transform: translateY(-1px) rotate(-45deg);
    }

    cursor: pointer;
    display: block;
    position: absolute;
    top: 50%;
    z-index: 1080;
  }

  .lg-prev {
    left: 10px;
  }
  .lg-next {
    right: 10px;
    transform: rotate(180deg);
  }
}


.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
.lg-outer.lg-right-end .lg-object {
  animation: lg-right-end 0.3s;
  position: relative;
}
.lg-outer.lg-left-end .lg-object {
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;

  .lg-close {
    position: fixed;
    top: 50px;
    right: 50px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: block;
    z-index: 10000;

    &:before,
    &:after {
      content: '';
      width: 25px;
      height: 2px;
      display: block;
      background: white;
      border-radius: 2px;
    }

    &:before {
      transform: translateY(1px) rotate(45deg);
    }

    &:after {
      transform: translateY(-1px) rotate(-45deg);
    }
  }
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: white;
  font-size: 24px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
  margin-bottom: 20px;
}
.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}
.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transform-origin: 50% 50%;
}
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}
.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}
.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}
.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}
.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}
@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color 0.25s ease;
    -o-transition: border-color 0.25s ease;
    transition: border-color 0.25s ease;
  }
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}
.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}
.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}
.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease;
}
.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}
.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
}
.lg-outer .lg-toogle-thumb:after {
  content: "\e1ff";
}
.lg-outer .lg-toogle-thumb:hover {
  color: #FFF;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}
.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}
.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}
.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}
.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}
.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}
.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s;
}
.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}
.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}
.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}
.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}
#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}
#lg-zoom-out:after {
  content: "\e312";
}
.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 22px;
}
.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}
.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}
.lg-pager-thumb-cont {
  display: none !important;
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  background: white;
}
.lg-outer .lg-pager {
  border-radius: 50%;
  border: 1px solid white;
  display: block;
  height: 13px;
  width: 12px;
}
.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}
.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}
.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}
.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}
.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF;
}
.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
}
.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px;
}
.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}
.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}
.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
}
.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}
.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}
.lg-outer #lg-share {
  position: relative;
}
.lg-outer #lg-share:after {
  content: "\e80d";
}
.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}
.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e901";
}
.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}
.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e904";
}
.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}
.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e902";
}
.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}
.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e903";
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.lg-outer.lg-visible {
  opacity: 1;
}
.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  transition-duration: inherit !important;
  transition-timing-function: inherit !important;
}
.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  transition-duration: 0s !important;
  opacity: 1;
}
.lg-outer.lg-grab img.lg-object {
  cursor: grab;
}
.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: grabbing;
}
.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}
.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}
.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}
.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}
.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.lg-outer .lg-item.lg-complete {
  background-image: none;
}
.lg-outer .lg-item.lg-current {
  z-index: 1060;
}
.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}
.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-empty-html {
  display: none;
}
.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
}
.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  transition: none 0s ease 0s !important;
}
.lg-css3.lg-use-css3 .lg-item {
  backface-visibility: hidden;
}
.lg-css3.lg-use-left .lg-item {
  backface-visibility: hidden;
}
.lg-css3.lg-fade .lg-item {
  opacity: 0;
}
.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}
.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  transition: opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}
.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}
